import { useParams } from "react-router-dom";

type Params = {
  gameId: string;
  deckId: string;
};

const useGameAndDeck = () => {
  const { gameId, deckId } = useParams<Params>();

  // Parse and validate gameId as number
  const parsedGameId = gameId ? parseInt(gameId, 10) : null;

  if (gameId && (!parsedGameId || isNaN(parsedGameId))) {
    throw new Error(`Invalid gameId: ${gameId} is not a valid number.`);
  }

  if (!deckId) {
    throw new Error(`Invalid deckId: deckId is required.`);
  }

  return {
    gameId: parsedGameId,
    deckId,
  };
};

export default useGameAndDeck;
