import { useTranslation } from "react-i18next";
import {
  BoxFlyweight,
  CharacterTemplatePanel,
  GameTamplateBox,
  GameTemplate,
} from "../../../../../model/Game";
import { PanelMenuProps } from "./panel-menu/PanelMenu";
import "./TemplatePanelView.css";
import SelectField, {
  LoseOption,
} from "../../../../../shared/components/logic/SelectField";
import { Conditional } from "../../../../../shared/components/logic/Conditional";
import { useTemplates } from "../../../../chat/hooks/useCards";
import {
  updatePanel,
  useGamePanels,
} from "../../../../tokens/hooks/useGameDbPanels";
import { useHover } from "../../../../../shared/hooks/useHover";
import { cuni } from "../../../../../shared/services/cuni/cuni";
import { uuid } from "../../../../../shared/utils/uuid";
import { useMapDragHandler } from "../../../../dices/MapDragProvider";
import { useMaps } from "../../../../map-hex/components/MapProvider";
import { GameCardView } from "../../../../cards/GameCardView";
import {
  removeFromHand,
  usePutDetails,
} from "../../../../board/use-put-on-table";
import { MapHexDrop } from "../../../../map-hex/MapHexDrop";
import { useDrop } from "react-dnd";
import { DeckInstanceProxy } from "../../../../cards/DeckInstanceProxy";
import { PanelProxy } from "../../../../cards/PanelProxy";
import { dev } from "../../../../../shared/utils/log";

interface TemplatePanelViewProps extends Pick<PanelMenuProps, "panelOnMap"> {
  panel: CharacterTemplatePanel;
  path: string;
}

export const TemplatePanelView = (props: TemplatePanelViewProps) => {
  const { panel } = props;
  const { t } = useTranslation();
  const templates = useTemplates();

  const template = templates.find((x) => x.key === panel?.templateKey);
  const selectField = (
    <SelectField<LoseOption>
      label={t("CARD_TYPES.TYPE")}
      value={template}
      onChange={(e: LoseOption) => {
        updatePanel<CharacterTemplatePanel>(panel.key, (db) => {
          db.templateKey = e.key;
          return db;
        });
      }}
      options={templates}
    />
  );

  return (
    <div className="template-panel-view">
      <Conditional
        condition={template}
        success={() => (
          <BoardGameTemplateView template={template!} panelKey={panel.key} />
        )}
        failure={() => selectField}
      />
    </div>
  );
};

export interface BoardGameTemplateViewProps {
  panelKey: string;
  template: GameTemplate;
}

const BoardGameTemplateView = (props: BoardGameTemplateViewProps) => {
  const { template } = props;

  return (
    <div
      style={{
        backgroundImage: `url(${template.backgroundUrl})`,
        width: template.sizeX,
        height: template.sizeY,
      }}
      className="board-game-template-view__container"
    >
      <BoardGameTemplateViewHandlerList type="panel" {...props} />
    </div>
  );
};

interface PanelBoardGameTemplateViewProps extends PanelProxy {
  template: GameTemplate;
}
interface DeckInstanceBoardGameTemplateViewProps extends DeckInstanceProxy {
  template: GameTemplate;
}

type BoardGameTemplateViewHandlerListProps =
  | PanelBoardGameTemplateViewProps
  | DeckInstanceBoardGameTemplateViewProps;

export const BoardGameTemplateViewHandlerList = (
  props: BoardGameTemplateViewHandlerListProps,
) => {
  const { template } = props;

  return (
    <>
      {template.boxes?.map((box) => (
        <BoardGameTemplateViewHandler key={box.id} {...props} box={box} />
      ))}
    </>
  );
};

interface PanelBoardGameTemplateViewHandlerProps extends PanelProxy {
  box: GameTamplateBox;
}
interface DeckInstanceBoardGameTemplateViewHandlerProps
  extends DeckInstanceProxy {
  box: GameTamplateBox;
}

type BoardGameTemplateViewHandlerProps =
  | PanelBoardGameTemplateViewHandlerProps
  | DeckInstanceBoardGameTemplateViewHandlerProps;

const BoardGameTemplateViewHandler = (
  props: BoardGameTemplateViewHandlerProps,
) => {
  const { box, type } = props;

  const { t } = useTranslation();
  const { isHovered, ...hoverHandlers } = useHover();
  const { current: currentDragHandler } = useMapDragHandler();
  const { all, current } = useMaps();
  const onMouseUp = (e: any) => {
    if (current && currentDragHandler.current && isHovered) {
      const currentCard = current.deckPlaces.find(
        (x) => x.key === currentDragHandler.current,
      );
      if (currentCard?.type !== "card" || !currentCard.card?.key) return;
      const cardKey = currentCard.card.key;

      const boxFlyweight = {
        key: uuid(),
        boxKey: box.id,
        deckFlyweightKey: currentCard.deckFlyweightKey ?? currentCard.key,
        cardKey,
      };
      if (type === "panel")
        updatePanel(props.panelKey, (state: CharacterTemplatePanel) => {
          state.boxes ??= [];
          state.boxes.push(boxFlyweight);
          return state;
        });
      else if (type === "deck") {
        const deck = current?.deckPlaces?.find(
          (x) => x.key === props.deckInstanceKey,
        );
        if (!deck) return;
        deck.boxes ??= [];
        deck.boxes.push(boxFlyweight);
        cuni.object.update("maps", all, deck);
      }
      const toRemove = current.deckPlaces.find(
        (x) => x.key === currentCard.key,
      );
      e.stopPropagation();
      e.preventDefault();
      cuni.object.remove("maps", all, toRemove);
      cuni.log(t("LOG.ON_HAND"), {
        type: "OnHand",
        cardKeyList: [currentCard.card.key],
      });
    }
  };
  const getPutDetails = usePutDetails();

  const handleDrop = (item: MapHexDrop) => {
    const { card, deckFlyweightKey } = getPutDetails(item);
    if (!card) return;
    const boxFlyweight = {
      key: uuid(),
      deckFlyweightKey,
      cardKey: card.key,
      boxKey: box.id,
    };
    if (type === "panel")
      updatePanel(props.panelKey, (state: CharacterTemplatePanel) => {
        state.boxes.push(boxFlyweight);
        return state;
      });
    else if (type === "deck") {
      const deck = current?.deckPlaces?.find(
        (x) => x.key === props.deckInstanceKey,
      );
      if (!deck) return;
      deck.boxes ??= [];
      deck.boxes.push(boxFlyweight);
      cuni.object.update("maps", all, deck);
    }

    removeFromHand([item]);
    cuni.log(t("LOG.ON_HAND"), {
      type: "OnHand",
      cardKeyList: [card.key],
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TOKEN-CARD",
    drop: (item: MapHexDrop) => {
      handleDrop(item);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const panels = useGamePanels();

  let boxes: BoxFlyweight[] = [];

  if (type === "panel") {
    const panel = panels.find((x) => x.panel.key === props.panelKey)
      ?.panel as CharacterTemplatePanel;
    boxes = panel?.boxes;
  } else {
    const deck = current?.deckPlaces?.find(
      (x) => x.key === props.deckInstanceKey,
    );
    boxes = deck?.boxes || [];
  }

  if (!boxes) return <></>;

  const boxInstances = boxes.filter(
    (x) => x.boxKey === box.id,
  ) as BoxFlyweight[];

  const style: React.CSSProperties = {
    width: box.width,
    height: box.height,
    transform: `rotate(${box.rotation}deg)`,
    borderRadius: box.shape === "circle" ? "50%" : "8px",
    left: box.x,
    top: box.y,
  };
  if (isOver) {
    style.borderColor = "gray";
  }

  return (
    <div
      ref={drop}
      {...hoverHandlers}
      onMouseUp={onMouseUp}
      className="board-game-template-view__handler"
      style={style}
    >
      {boxInstances?.map((boxInstance) => {
        return (
          <GameCardView key={boxInstance.key} card={boxInstance} {...props} />
        );
      })}
    </div>
  );
};
