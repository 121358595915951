import { MapCardPlace } from "../../../model/Map";
import { useGame } from "../../character/hooks/useGame";
import { useMaps } from "../../map-hex/components/MapProvider";


export const useDeckProperties = (deck: MapCardPlace) => {
  const { current } = useMaps();
  const { game } = useGame();
  let originalDeck = game.root.settings?.decks?.find(
    (x) => x.key === deck.deckKey || x.key === deck.card?.deckKey
  );

  if (!originalDeck && deck.subType === "discardPile") {
    const rootDeckPlace = current?.deckPlaces.find(
      (x) => x.key === deck.subTypeDeckInstanceKey
    );
    originalDeck = game.root.settings?.decks?.find(
      (x) => x.key === rootDeckPlace?.deckKey
    );
  }

  const avatarUrl = deck.type === "card"
    ? deck.flipped
      ? originalDeck?.cardReverseAvatarUrl
      : deck.card?.avatarUrl
    : originalDeck?.reverseAvatarUrl;

  const uiType = deck.type === "card" ? deck.card?.uiType : originalDeck?.uiType;
  const color = deck.type === "card"
    ? deck.card?.textColor || originalDeck?.cardTextColor
    : originalDeck?.textColor;
  const backgroundColor = deck.type === "card"
    ? deck.card?.color || originalDeck?.cardColor
    : originalDeck?.color;
  const size = deck.card?.size ?? originalDeck?.size ?? undefined;
  const width = size || 160;
  const height = deck.card?.sizeY ??
    originalDeck?.sizeY ??
    (["CARD", undefined].includes(uiType) ? width * 1.5 : width);



  return {
    originalDeck,
    avatarUrl,
    backgroundColor,
    color,
    height,
    uiType,
    width,
  };
};
