import { useTranslation } from "react-i18next";
import {
  Tooltip,
  IconButton,
  Box,
  Button,
  Modal,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ModalProps } from "../../../shared/components/modal/ModalProps";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CardFlyweight,
  Character,
  CharacterCardPanel,
  GameGrantPermission,
} from "../../../model/Game";
import { uuid } from "../../../shared/utils/uuid";
import { useUser } from "../../auth/useUser";
import { useCards } from "../../chat/hooks/useCards";
import { CardView } from "../CardView";
import { ReactComponent as CardIcon } from "../../../assets/icons/card.svg";
import { useGranPermission } from "./useGranPermission";
import { updatePanel } from "../../tokens/hooks/useGameDbPanels";
import SelectField, {
  LoseOption,
} from "../../../shared/components/logic/SelectField";
import { useGame } from "../../character/hooks/useGame";
import { Conditional } from "../../../shared/components/logic/Conditional";

export const CardTake = (props: { character: Character }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  return (
    <>
      <CardTakeModal {...props} open={open} handleClose={close} />
      <Tooltip title={t("CARD.TAKE")}>
        <IconButton onClick={() => setOpen(true)}>
          <CardIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

type CardTakeModalProps = ModalProps & {
  character: Character;
};

interface FormProps {
  action: "PREVIEW" | "TAKE" | "RANDOM";
}

const CardTakeModal = (props: CardTakeModalProps) => {
  const { open, handleClose: close, character } = props;

  const { t } = useTranslation();
  const { game } = useGame();

  const { watch, handleSubmit, reset, getValues, setValue } =
    useForm<FormProps>({
      defaultValues: {
        action: "PREVIEW",
      },
    });

  useEffect(() => {
    reset({
      action: "PREVIEW",
    });
  }, [reset]);

  const user = useUser();
  const { accepted, askForPermission, handleClose, loading } =
    useGranPermission({ close });

  const onSubmit = (form: FormProps) => {
    if (!user) return;
    const gamePermission: GameGrantPermission = {
      // TODO: remember key of submit!
      key: uuid(),
      creatorId: user.id,
      question: t("CARD.TAKE_QUESTION_" + getValues("action"), {
        user: user.userName,
      }),
      answers: [],
    };
    askForPermission(gamePermission);
  };

  const cards = useCards();
  const cardPanel = character.__panels?.find((x) => x.__type === "card");

  // const { current, all } = useMaps();
  // const currentPlace = current?.deckPlaces.find((x) => x.key === deckId);
  const takeCard = useCallback(
    (card: CardFlyweight) => {
      const ownDeck = Object.entries(game.characters)
        .map((x) => x[1])
        .filter((x) => x.__userId === user?.id)
        .map((x) => x.__panels?.find((x) => x.__type === "card"))?.[0];
      if (!cardPanel || !ownDeck) return;
      // remove
      updatePanel(cardPanel.key, (state: CharacterCardPanel) => {
        state.cards = state.cards.filter((x) => x.key !== card.key);
        return state;
      });
      // for now we need to delay update panel to be in sync
      setTimeout(() => {
        updatePanel(ownDeck.key, (state: CharacterCardPanel) => {
          state.cards.push(card);
          return state;
        });
        handleClose();
      }, 100);
    },
    [cardPanel, game.characters, handleClose, user?.id],
  );

  const resetRef = useRef(false);

  useEffect(() => {
    if (resetRef.current) return;
    if (!cardPanel?.cards) return;
    if (!accepted) return;
    if (watch("action") === "RANDOM") {
      // avoid duplicate execution
      resetRef.current = true;
      setTimeout(() => (resetRef.current = false), 1000);

      const cards = cardPanel.cards as CardFlyweight[];
      const index = getRandomInt(cards.length);
      takeCard(cards[index]);
      handleClose();
    }
  }, [accepted, cardPanel?.cards, handleClose, takeCard, watch]);

  const component = loading ? (
    <Box display="flex" justifyContent="center" padding="1rem">
      <CircularProgress />
    </Box>
  ) : accepted && cardPanel?.cards && watch("action") !== "RANDOM" ? (
    <>
      <Conditional condition={watch("action") === "TAKE"}>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("CARD.TAKE_DESC_ACCEPTED")}
        </Typography>
      </Conditional>

      <Box display="flex" gap="1rem" flexWrap="wrap" className="modal-max-height">
        {(cardPanel.cards as CardFlyweight[]).map((x: CardFlyweight) => {
          const data = cards.find((card) => card.key === x.cardKey);
          if (!data) return <></>;
          return (
            <CardView
              {...data}
              key={x.cardKey}
              onClick={
                watch("action") === "TAKE" ? () => takeCard(x) : undefined
              }
            />
          );
        })}
      </Box>
    </>
  ) : (
    <>
      <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
        {t("CARD.TAKE_DESC")}
      </Typography>
      <SelectField<LoseOption>
        label={t("CARD_TYPES.TYPE")}
        value={{ key: watch("action"), name: watch("action") }}
        onChange={(e: LoseOption) => {
          setValue("action", e.key);
        }}
        options={[
          { key: "PREVIEW", name: t("CARD.OPTION_PREVIEW") },
          { key: "TAKE", name: t("CARD.OPTION_TAKE") },
          { key: "RANDOM", name: t("CARD.OPTION_RANDOM") },
        ]}
      />
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("CARD.TAKE")}
          </Typography>
          {component}

          <Conditional condition={!accepted && !loading}>
            <Button type="submit" variant="contained">
              {t("COMMON.SEND")}
            </Button>
          </Conditional>

          <Button onClick={handleClose}>{t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
