import { config } from "../../../config";
import "./ProjectList.css";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../shared/services/apiClient";
import { useQuery } from "@tanstack/react-query";
import { ProjectListActions } from "./ProjectListActions";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { GameInfo } from "../../../shared/components/ui/info/GameInfo";
import { calculateBackground } from "../../../shared/hooks/useBackgoundImage";
import { useTranslation } from "react-i18next";
import { Project } from "../../../model/Project";
import {
  aiTest,
  completePayments,
  testPayments,
  updatePayments,
} from "../../payments/use-payments";

export const ProjectList = () => {
  const { data: projects } = useProjectAll();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const items = projects?.map((project) => (
    <div key={project.id} className="paper game-item-wrapper">
      <div className="game-item">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundImage: calculateBackground(project.url),
          }}
          className="game-wallpaper"
        />
        <div className="game-item-buttons">
          <Typography style={{ zIndex: 10 }} variant="h5">
            {project.name}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/project/${project.id}`);
            }}
            startIcon={<PlayArrowIcon />}
          >
            {t("COMMON.EDIT")}
          </Button>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="game-list">
      <GameInfo>
        {t("INFO.GAME_LIST_TIP")}
      </GameInfo>
      {items}

      <div className="paper game-item-wrapper">
        <div className="game-item">
          <Button variant="contained" onClick={testPayments}>
            Test
          </Button>
          <Button variant="contained" onClick={completePayments}>
            Complete
          </Button>
          <Button variant="contained" onClick={updatePayments}>
            Update
          </Button>
          <Button variant="contained" onClick={aiTest}>
            AI TEST
          </Button>
        </div>
      </div>
      <div className="paper game-item-wrapper">
        <div className="game-item">
          <ProjectListActions />
        </div>
      </div>
    </div>
  );
};

export const useProjectAll = () => {
  return useQuery({
    queryKey: ["project-all"],
    queryFn: () =>
      apiClient
        .get<Project[]>(`${config.baseUrl}/api/project`)
        .then((res) => res.data),
  });
};
