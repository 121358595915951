import { Box, Button, Tab, Tabs, TextField, Typography } from "@mui/material";
import { GameCard, GameDeck, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { useTranslation } from "react-i18next";
import { useGame } from "../character/hooks/useGame";
import { cuni } from "../../shared/services/cuni/cuni";
import { Controller, useForm } from "react-hook-form";
import { CardView } from "../cards/CardView";
import SelectField, {
  SelectOption,
} from "../../shared/components/logic/SelectField";
import { ColorPicker } from "../../shared/components/ui/color-picker/ColorPicker";
import { Divider } from "../../shared/components/ui/Divider";
import UploadButton, {
  UploadResult,
} from "../../shared/components/logic/UploadButton";
import { DeckSetView } from "./DeckSetView";
import { CheckboxGenericInput } from "../character/components/generic/components/inputs/CheckboxGenericInput";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { useState } from "react";
import { Switch, Case } from "../../shared/components/logic/Switch";
import SettingsIcon from "@mui/icons-material/Settings";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { useNavigate } from "react-router-dom";

interface CardDetailEditViewProps {
  deck: GameDeck;
}

interface Form {
  name: string;
  reverseAvatarUrl: string;
  group: string;
  textColor?: string;
  color?: string;
  uiType?: GameCard["uiType"];
  cardTextColor?: string;
  cardColor?: string;
  size?: number;
  sizeY?: number;
  cardReverseAvatarUrl?: string;
  actionHidden?: boolean;
  keepOrder?: boolean;
}

export const DeckDetailEditView = (props: CardDetailEditViewProps) => {
  const { deck } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { game } = useGame();

  const { register, control, setValue, watch } = useForm<Form>({
    defaultValues: deck,
  });

  const update = (updater: (t: GameDeck) => void) => {
    const decks = game.root.settings?.decks ?? [];
    const dbItem = decks.find((x) => x.key === deck.key);
    if (!dbItem) return;
    updater(dbItem);
    cuni.update(SETTINGS_ADMIN_KEY, "decks", decks);
  };

  const [view, setView] = useState("general");
  return (
    <div className="column">
      <div className="paper-outline">
        <Tabs
          value={view}
          onChange={(_, nweValue) => setView(nweValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab key="general" value="general" label={<SettingsIcon />} />
          <Tab key="colors" value="colors" label={<ColorLensIcon />} />
          <Tab key="design" value="design" label={<DesignServicesIcon />} />
        </Tabs>
      </div>

      <div className="designing-view">
        <div className="designing-view-left">
          <CardView {...deck} avatarUrl={deck.reverseAvatarUrl} />
        </div>
        <div className="designing-view-right">
          <Switch current={view}>
            <Case
              value="general"
              render={
                <>
                  <TextField
                    label={t("COMMON.NAME")}
                    {...register("name")}
                    onBlur={(e) => {
                      update((t) => (t.name = e.currentTarget.value));
                    }}
                  />
                  <Box display="flex" gap="1rem" width="100%">
                    <CheckboxGenericInput
                      label={t("COMMON.ACTION_HIDE")}
                      value={!!watch("actionHidden")}
                      onChange={(e) => {
                        setValue("actionHidden", e);
                        update((t) => (t.actionHidden = e));
                      }}
                    />
                    <CheckboxGenericInput
                      label={t("COMMON.KEEP_ORDER")}
                      value={!!watch("keepOrder")}
                      onChange={(e) => {
                        setValue("keepOrder", e);
                        update((t) => (t.keepOrder = e));
                      }}
                    />
                  </Box>

                  <Box display="flex" gap="1rem" width="100%">
                    <TextField
                      style={{
                        flex: 1,
                      }}
                      label={t("COMMON.SIZE")}
                      {...register("size")}
                      onBlur={(e) => {
                        update(
                          (t) => (t.size = parseInt(e.currentTarget.value)),
                        );
                      }}
                    />
                    <TextField
                      style={{
                        flex: 1,
                      }}
                      label={t("COMMON.SIZE_Y")}
                      {...register("sizeY")}
                      onBlur={(e) => {
                        update(
                          (t) => (t.sizeY = parseInt(e.currentTarget.value)),
                        );
                      }}
                    />
                  </Box>
                  <Box
                    display="grid"
                    gap="1rem"
                    gridTemplateColumns="1fr 200px"
                  >
                    <TextField
                      label={t("TOKEN.AVATAR")}
                      {...register("reverseAvatarUrl")}
                      onBlur={(e) => {
                        update(
                          (t) => (t.reverseAvatarUrl = e.currentTarget.value),
                        );
                      }}
                    />
                    <UploadButton
                      label={`${t("COMMON.UPLOAD")}`}
                      onUpload={(data: UploadResult[]) => {
                        const { location: avatarUrl } = data![0];
                        update((t) => (t.reverseAvatarUrl = avatarUrl));
                        setValue("reverseAvatarUrl", avatarUrl);
                      }}
                    />
                  </Box>

                  <Box
                    display="grid"
                    gap="1rem"
                    gridTemplateColumns="1fr 200px"
                  >
                    <TextField
                      label={t("TOKEN.REVERSE_AVATAR")}
                      {...register("cardReverseAvatarUrl")}
                      onBlur={(e) => {
                        update(
                          (t) =>
                            (t.cardReverseAvatarUrl = e.currentTarget.value),
                        );
                      }}
                    />
                    <UploadButton
                      label={`${t("COMMON.UPLOAD")}`}
                      onUpload={(data: UploadResult[]) => {
                        const { location: avatarUrl } = data![0];
                        update((t) => (t.cardReverseAvatarUrl = avatarUrl));
                        setValue("cardReverseAvatarUrl", avatarUrl);
                      }}
                    />
                  </Box>

                  <Controller
                    name="uiType"
                    control={control}
                    render={({ field }) => {
                      return (
                        <SelectField<SelectOption>
                          label={t("CARD_TYPES.TYPE")}
                          value={{
                            key: field.value,
                            name: t(`CARD_TYPES.${field.value}`),
                          }}
                          onChange={(e: SelectOption) => {
                            const uiType =
                              typeof e === "object" && "key" in e
                                ? (e.key as GameCard["uiType"])
                                : "CARD";
                            setValue("uiType", uiType);
                            update((t) => (t.uiType = uiType));
                          }}
                          options={["FREE", "SQUARE", "ROUND", "CARD"].map(
                            (x) => ({
                              key: x,
                              name: t(`CARD_TYPES.${x}`),
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </>
              }
            />
            <Case
              value="colors"
              render={
                <>
                  <Divider title={t("CARD.BACK")} />
                  <Box display="flex" gap="1rem">
                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      padding="6px 14px"
                      gap="0.25rem"
                      sx={{
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                        variant="body1"
                      >
                        {t("GAME.COLOR")}
                      </Typography>
                      <ColorPicker
                        selected={watch("cardColor")}
                        onColorChange={(color) => {
                          setValue("cardColor", color);
                          update((t) => (t.cardColor = color));
                        }}
                      />
                    </Box>

                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      padding="6px 14px"
                      gap="0.25rem"
                      sx={{
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                        variant="body1"
                      >
                        {t("GAME.TEXT_COLOR")}
                      </Typography>

                      <ColorPicker
                        selected={watch("cardTextColor")}
                        onColorChange={(color) => {
                          setValue("cardTextColor", color);
                          update((t) => (t.cardTextColor = color));
                        }}
                      />
                    </Box>
                  </Box>
                  <Divider title={t("CARD.FRONT")} />
                  <Box display="flex" gap="1rem">
                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      padding="6px 14px"
                      gap="0.25rem"
                      sx={{
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                        variant="body1"
                      >
                        {t("GAME.COLOR")}
                      </Typography>
                      <ColorPicker
                        selected={watch("color")}
                        onColorChange={(color) => {
                          setValue("color", color);
                          update((t) => (t.color = color));
                        }}
                      />
                    </Box>

                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      padding="6px 14px"
                      gap="0.25rem"
                      sx={{
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                        variant="body1"
                      >
                        {t("GAME.TEXT_COLOR")}
                      </Typography>

                      <ColorPicker
                        selected={watch("textColor")}
                        onColorChange={(color) => {
                          setValue("textColor", color);
                          update((t) => (t.textColor = color));
                        }}
                      />
                    </Box>
                  </Box>
                </>
              }
            />
            <Case value="design" render={<></>} />
          </Switch>
        </div>
      </div>

      <DeckSetView deckKey={deck.key} />
      <Button
        variant="outlined"
        onClick={() => {
          navigate(`/print/${game.id}/deck/${deck.key}`);
        }}
      >
        Print
      </Button>
    </div>
  );
};
