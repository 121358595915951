import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { GameDice, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { useTranslation } from "react-i18next";
import { useGame } from "../character/hooks/useGame";
import PreviewIcon from "@mui/icons-material/Preview";
import { Popover } from "../../shared/components/ui/popover/Popover";
import { cuni } from "../../shared/services/cuni/cuni";
import { FormProvider, useForm } from "react-hook-form";
import { ColorPickerField } from "../chat/components/color-picker-field/ColorPickerField";
import { ColorPicker } from "../../shared/components/ui/color-picker/ColorPicker";
import { DiceView } from "./DiceView";
import { useState } from "react";
import { Switch, Case } from "../../shared/components/logic/Switch";
import SettingsIcon from "@mui/icons-material/Settings";
import ColorLensIcon from "@mui/icons-material/ColorLens";

interface CardDetailEditViewProps {
  dice: GameDice;
  index: number;
}

interface FormValues {
  name: string;
  color?: string;
  textColor?: string;
  fields: string;
  noParse?: boolean;
}

export const DiceDetailEditView = (props: CardDetailEditViewProps) => {
  const { dice } = props;

  const { t } = useTranslation();

  const { game } = useGame();

  const form = useForm<FormValues>({
    defaultValues: {
      ...dice,
      fields: dice.fields?.join(","),
    },
  });
  const { register, setValue } = form;

  const update = (updater: (t: GameDice) => void) => {
    const dices = game.root.settings?.dices ?? [];
    const dbItem = dices.find((x) => x.key === dice.key);
    if (!dbItem) return;
    updater(dbItem);
    cuni.update(SETTINGS_ADMIN_KEY, "dices", dices);
  };
  const [view, setView] = useState("general");

  return (
    <FormProvider {...form}>
      <div className="column">
        <div className="paper-outline">
          <Tabs
            value={view}
            onChange={(_, nweValue) => setView(nweValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab key="general" value="general" label={<SettingsIcon />} />
          </Tabs>
        </div>

        <div className="designing-view">
          <div className="designing-view-left">
            <DiceView item={dice} fixed />
          </div>
          <div className="designing-view-right">
            <Switch current={view}>
              <Case
                value="general"
                render={
                  <>
                    <TextField
                      label={t("COMMON.NAME")}
                      {...register("name")}
                      onBlur={(e) => {
                        update((t) => (t.name = e.currentTarget.value));
                      }}
                    />
                    <Box display="flex" gap="1rem">
                      <Box
                        display="flex"
                        flex="1"
                        alignItems="center"
                        padding="6px 14px"
                        gap="0.25rem"
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 0.2)",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "rgba(255, 255, 255, 0.7)",
                          }}
                          variant="body1"
                        >
                          {t("GAME.COLOR")}
                        </Typography>
                        <ColorPickerField
                          onChange={(color) => {
                            update((t) => (t.color = color));
                          }}
                        />
                      </Box>

                      <Box
                        display="flex"
                        flex="1"
                        alignItems="center"
                        padding="6px 14px"
                        gap="0.25rem"
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 0.2)",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "rgba(255, 255, 255, 0.7)",
                          }}
                          variant="body1"
                        >
                          {t("GAME.TEXT_COLOR")}
                        </Typography>

                        <ColorPicker
                          selected={form.watch("textColor")}
                          onColorChange={(color) => {
                            setValue("textColor", color);
                            update((t) => (t.textColor = color));
                          }}
                        />
                      </Box>
                    </Box>
                    <TextField
                      label={t("TOKEN.SIDE")}
                      {...register("fields")}
                      onBlur={(e) => {
                        update(
                          (t) => (t.fields = e.currentTarget.value.split(",")),
                        );
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={form.watch("noParse")}
                          value={form.watch("noParse")}
                          onChange={() => {
                            const noParse = form.watch("noParse");
                            setValue("noParse", !noParse);
                            update((t) => (t.noParse = !noParse));
                          }}
                        />
                      }
                      label={t("TOKEN.NO_PARSE")}
                    />
                  </>
                }
              />
            </Switch>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
