import { Tooltip, IconButton, Modal, Box } from "@mui/material";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import React from "react";

export const ZoomElementIconButton = (props: { children: JSX.Element }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  return (
    <>
      <ZoomModal {...props} open={open} handleClose={close} />
      <Tooltip title={t("CARD.ZOOM")}>
        <IconButton onClick={() => setOpen(true)}>
          <ZoomInIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

type ZoomModalProps = {
  open: boolean; // Controls whether the modal is open
  handleClose: () => void; // Function to handle closing the modal
  children?: JSX.Element; // Optional: Content to render inside the modal
};

export const ZoomModal: React.FC<ZoomModalProps> = ({
  open,
  handleClose,
  children,
}) => {
  if (!children) return <></>;
  const clonedElement = React.cloneElement(children, {
    style: {
      ...children.props.style,
      position: "absolute",
      rotate: "0deg",
      pointerEvents: "none",
      transform: "translate(-50%, -50%) scale(2.0)",
      left: "50%",
      top: "50%",
    },
  });
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <>{clonedElement}</>
      </Modal>
    </>
  );
};
