import { useTranslation } from "react-i18next";
import { CharacterCardPanel } from "../../../../../model/Game";
import { AreYouSureModal } from "../../../../../shared/components/modal/AreYouSureModal";
import { cuni } from "../../../../../shared/services/cuni/cuni";
import { cardUtils } from "../../../../../shared/utils/cardUtils";
import { uuid } from "../../../../../shared/utils/uuid";
import { GameCardView } from "../../../../cards/GameCardView";
import { MapHexDrop } from "../../../../map-hex/MapHexDrop";
import { updatePanel } from "../../../../tokens/hooks/useGameDbPanels";
import { useGame } from "../../../hooks/useGame";
import "./CardPanelView.css";
import { useDrop } from "react-dnd";
import { MapCardPlace } from "../../../../../model/Map";
import { useState } from "react";
import {
  removeFromHand,
  usePutDetails,
} from "../../../../board/use-put-on-table";
import { MultiplePickDialog } from "./MultiplePickDialog";
import { PanelMenuProps } from "./panel-menu/PanelMenu";
import i18next from "i18next";

interface CardPanelViewProps extends Pick<PanelMenuProps, "panelOnMap"> {
  panel: CharacterCardPanel;
  path: string;
}

export const CardPanelView = (props: CardPanelViewProps) => {
  const { panel, panelOnMap } = props;
  const { game } = useGame();
  const { t } = useTranslation();
  const [multipleOpen, setMultipleOpen] = useState<MapCardPlace | null>(null);

  const getPutDetails = usePutDetails();

  const handleDrop = (item: MapHexDrop) => {
    const { card, deckFlyweightKey } = getPutDetails(item);
    if (!card) return;
    updatePanel(panel.key, (state: CharacterCardPanel) => {
      state.cards.push({
        key: uuid(),
        deckFlyweightKey,
        cardKey: card.key,
      });
      return state;
    });

    removeFromHand([item]);
    cuni.log(i18next.t("LOG.ON_HAND"), {
      type: "OnHand",
      cardKeyList: [card.key],
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TOKEN-CARD",
    drop: (item: MapHexDrop) => {
      if (item.type === "FROM_TABLE") {
        const { deck: deckPanel, multiple } = item;
        if (multiple) {
          setMultipleOpen(deckPanel);
        } else handleDrop(item);
      } else if (item.type === "FROM_HAND") {
        handleDrop(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        borderColor: isOver ? "gray" : "white",
      }}
      className="card-panel-view"
    >
      {panel.cards.map((card) => (
        <GameCardView
          key={card.key}
          card={card}
          type="panel"
          panelKey={panel.key}
          actions={panelOnMap?.actions?.(card)}
        />
      ))}

      <MultiplePickDialog
        panelKey={panel.key}
        mapPlace={multipleOpen}
        close={() => setMultipleOpen(null)}
      />
      <AreYouSureModal
        title={t("GAME.ADD_ROW")}
        content={t("GAME.ADD_ROW_DESC")}
        confirmAction={(fields) => {
          if (fields && parseInt(fields.value) && multipleOpen?.key) {
            const value = parseInt(fields.value);
            const cards: string[] = [];
            for (let i = 0; i < value; i++) {
              const card = cardUtils.pickFromDeck(game, multipleOpen.key);
              // TODO: if no card we should shuffle
              if (card) cards.push(card);
            }
            updatePanel(panel.key, (state: CharacterCardPanel) => {
              cards.forEach((x) => {
                state.cards.push({
                  key: uuid(),
                  deckFlyweightKey: multipleOpen.key,
                  cardKey: x,
                });
              });

              return state;
            });
            cuni.log(i18next.t("LOG.ON_HAND"), {
              type: "OnHand",
              cardKeyList: cards,
            });
          }
        }}
        open={!!multipleOpen}
        handleClose={() => setMultipleOpen(null)}
        fields={[
          {
            name: "value",
          },
        ]}
      />
    </div>
  );
};
