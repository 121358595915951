import "./DeckPlace.css";
import { MapCardPlace } from "../../../model/Map";
import { clsx } from "../../../shared/utils/clsx";
import { useMapDrag } from "../../dices/use-map-drag";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import { MapHexDrop } from "../../map-hex/MapHexDrop";
import { useMaps } from "../../map-hex/components/MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";
import { updatePanel } from "../../tokens/hooks/useGameDbPanels";
import { CharacterCardPanel } from "../../../model/Game";
import { useHover } from "../../../shared/hooks/useHover";
import { useMapDragHandler } from "../../dices/MapDragProvider";
import i18next from "i18next";
import { useDeckProperties } from "./useDeckProperties";
import { BoardGameTemplateViewHandlerList } from "../../character/components/generic/components/TemplatePanelView";
import { useCards, useTemplates } from "../../chat/hooks/useCards";
import { CardTokens } from "../CardView";

export interface DeckPlaceProps {
  fixed?: boolean;
  deck: MapCardPlace;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
  actions?: JSX.Element;
  style?: React.CSSProperties;
}
export const DeckPlace = (props: DeckPlaceProps) => {
  const { deck, onContextMenu, onClick, fixed = false, actions, style } = props;
  const { t } = useTranslation();
  const canDrop = !deck.deckKey && deck.type === "deck";

  const { isHovered, ...hoverHandlers } = useHover();
  const { current: currentDragHandler, setCurrent: setCurrentDragHanlder } =
    useMapDragHandler();
  const { all, current } = useMaps();

  // @ts-ignore
  const onMouseUp = (e) => {
    if (
      current &&
      currentDragHandler.current &&
      (isHovered || (e.changedTouches && e.changedTouches.length > 0)) &&
      currentDragHandler.current !== deck.key &&
      deck.type === "deck"
    ) {
      const currentCard = current.deckPlaces.find(
        (x) => x.key === currentDragHandler.current,
      );

      if (currentCard?.type !== "card" || !currentCard.card?.key) return;
      const bottomDeckPlace = current.deckPlaces.find(
        (x) => x.key === deck.key,
      );
      if (!bottomDeckPlace) return;

      cuni.array.push(
        "maps",
        all,
        bottomDeckPlace.cards,
        currentCard.card?.key,
      );
      const currentDeckPlace = current.deckPlaces.find(
        (x) => x.key === currentCard.key,
      );
      // I don't know what this is doing for + but for - is mess up drag handling
      e.stopPropagation();
      e.preventDefault();
      setCurrentDragHanlder(null);

      cuni.object.remove("maps", all, currentDeckPlace!);

      cuni.log(i18next.t("LOG.ON_DECK"), {
        type: "OnHand",
        cardKeyList: [currentCard.card?.key],
        deckInstanceKey: bottomDeckPlace.key,
      });
    }
  };

  const [{ isOver }, drop] = useDrop(
    {
      accept: "TOKEN-CARD",
      drop: (item: MapHexDrop) => {
        const canDrop = !deck.deckKey && deck.type === "deck";
        if (!canDrop) return;

        let cardKey = "";
        if (item.type === "FROM_TABLE") {
          if (item.deck.type === "deck") return;
          cardKey = item.deck.card!.key;
        }
        if (item.type === "FROM_HAND") {
          cardKey = item.card.key;
        }
        if (!cardKey) {
          console.error("no card key");
          return;
        }

        if (!current) return;
        const dbItem = current.deckPlaces.find((x) => x.key === deck.key);
        if (!dbItem) return;

        cuni.array.push("maps", all, dbItem.cards, cardKey);

        if (item.type === "FROM_TABLE") {
          const toRemove = current.deckPlaces.find(
            (x) => x.key === item.deck.key,
          );
          cuni.object.remove("maps", all, toRemove);
        }

        if (item.type === "FROM_HAND") {
          const { instance, panelKey } = item;
          updatePanel(panelKey, (state: CharacterCardPanel) => {
            state.cards = state.cards.filter((x) => x.key !== instance.key);
            return state;
          });
        }
        cuni.log(i18next.t("LOG.ON_DECK"), {
          type: "OnHand",
          cardKeyList: [cardKey],
          deckInstanceKey: dbItem.key,
        });
      },
      collect: (monitor) => {
        return {
          isOver: canDrop && !!monitor.isOver(),
        };
      },
    },
    [current, all, canDrop],
  );

  const handlers = useMapDrag({
    draggableDb: "deckPlaces",
    key: deck.key,
    position: deck.position,
    fixed,
  });

  const {
    avatarUrl,
    backgroundColor,
    color,
    height,
    originalDeck,
    uiType,
    width,
  } = useDeckProperties(deck);

  const cards = useCards();
  const card = cards.find((x) => x.key === deck.card?.key);

  const allHandlers = deck.fixed
    ? {}
    : {
        ...handlers,
        ...hoverHandlers,
        onMouseUp,
        onTouchEnd: onMouseUp,
      };

  const deckView =
    deck.type === "deck" &&
    (uiType === "CARD" || uiType === "SQUARE" || uiType === undefined) ? (
      <>
        {[0, 2, 4, 6].map((x) => (
          <>
            <div
              className="deck-place-multi-v"
              style={{ right: x - 12, top: 12 - x }}
            />
            <div
              className="deck-place-multi-h"
              style={{ bottom: x - 12, left: 11 - x }}
            />
          </>
        ))}
      </>
    ) : (
      <></>
    );

  return (
    <div
      ref={drop}
      {...allHandlers}
      style={{
        filter: deck.subType === "discardPile" ? "grayscale()" : undefined,
        height,
        width,
        backgroundColor:
          uiType === "FREE" ? "transparent" : backgroundColor || "black",
        color: color || "white",
        backgroundImage: `url(${avatarUrl})`,
        backgroundSize: "cover",
        rotate: `${deck.rotate || 0}deg`,
        border: canDrop && isOver ? "red" : undefined,
        ...handlers.style,
        ...style,
      }}
      className={clsx("deck-place", {
        [`uiType-big-${uiType}`]: !!uiType,
        "disable-map": !deck.fixed,
        "no-drag": !deck.fixed,
      })}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      {actions}
      {deck.card
        ? avatarUrl
          ? ""
          : deck.card.name
        : avatarUrl
        ? ""
        : `${originalDeck?.name || t("COMMON.EMPTY")} [${deck?.cards.length}]`}
      <span className="deck-place-hover">
        {deck.card
          ? deck.card.name
          : `${originalDeck?.name || t("COMMON.EMPTY")} [${
              deck?.cards.length
            }]`}
      </span>
      {deckView}

      {card?.tokens && <CardTokens tokens={card?.tokens} />}

      <DeckPlaceHandlerListAdapter deck={deck} />
    </div>
  );
};

interface DeckPlaceHandlerListAdapterProps {
  deck: MapCardPlace;
}

const DeckPlaceHandlerListAdapter = (
  props: DeckPlaceHandlerListAdapterProps,
) => {
  const { deck } = props;
  const templates = useTemplates();
  const template = templates.find((x) => x.key === deck?.templateKey);
  if (!template) return <></>;
  return (
    <BoardGameTemplateViewHandlerList
      template={template}
      deckInstanceKey={deck.key}
      type="deck"
    />
  );
};
