import React from 'react';

// Switch Component
type SwitchProps = {
  current: string | number; // The current value to match
  children: React.ReactNode; // Children which will be `Case` components
};

export const Switch: React.FC<SwitchProps> = ({ current, children }) => {
  // Render the first matching Case
  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.props.value === current) {
          return child.props.render;
        }
        return null;
      })}
    </>
  );
};

// Case Component
type CaseProps = {
  value: string | number; // Value to match
  render: React.ReactNode; // JSX Element to render when matched
};

export const Case: React.FC<CaseProps> = ({ render }) => {
  return <>{render}</>;
};