import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { GameTemplate, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { useTranslation } from "react-i18next";
import { useGame } from "../character/hooks/useGame";
import PreviewIcon from "@mui/icons-material/Preview";
import { Popover } from "../../shared/components/ui/popover/Popover";
import { cuni } from "../../shared/services/cuni/cuni";
import { FormProvider, useForm } from "react-hook-form";
import { ColorPickerField } from "../chat/components/color-picker-field/ColorPickerField";
import { ColorPicker } from "../../shared/components/ui/color-picker/ColorPicker";
import { BoardGameTemplate } from "./BoardGameTemplate";
import UploadButton, {
  UploadResult,
} from "../../shared/components/logic/UploadButton";

interface TemplateDetailEditViewProps {
  template: GameTemplate;
  index: number;
}

interface FormValues {
  name: string;
  backgroundUrl?: string;
  sizeX?: number;
  sizeY?: number;
}

export const TemplateDetailEditView = (props: TemplateDetailEditViewProps) => {
  const { template } = props;

  const { t } = useTranslation();

  const { game } = useGame();

  const form = useForm<FormValues>({
    defaultValues: {
      ...template,
    },
  });
  const { register, setValue } = form;

  const update = (updater: (t: GameTemplate) => void) => {
    const templates = game.root.settings?.templates ?? [];
    const dbItem = templates.find((x) => x.key === template.key);
    if (!dbItem) return;
    updater(dbItem);
    cuni.update(SETTINGS_ADMIN_KEY, "templates", templates);
  };
  return (
    <FormProvider {...form}>
      <div className="column">
        <div className="paper-outline">
          <Popover
            renderButton={(onClick) => (
              <Tooltip title={t("TOKEN.PREVIEW")}>
                <IconButton onClick={onClick}>
                  <PreviewIcon />
                </IconButton>
              </Tooltip>
            )}
          >
            {/* <DiceView item={template} fixed /> */}
          </Popover>
        </div>
        <Box display="grid" gap="1rem" gridTemplateColumns="1fr 1fr 1fr">
          <TextField
            label={t("COMMON.NAME")}
            {...register("name")}
            onBlur={(e) => {
              update((t) => (t.name = e.currentTarget.value));
            }}
          />
          <TextField
            label={t("MAP.BACKGROUND")}
            {...register("backgroundUrl")}
            onBlur={(e) => {
              update((t) => (t.backgroundUrl = e.currentTarget.value));
            }}
          />
          <UploadButton
            label={`${t("COMMON.UPLOAD")}`}
            onUpload={(data: UploadResult[]) => {
              const { location: avatarUrl } = data![0];
              update((t) => (t.backgroundUrl = avatarUrl));
              setValue("backgroundUrl", avatarUrl);
            }}
          />
        </Box>
        <Box display="grid" gap="1rem" gridTemplateColumns="1fr 1fr ">
          <TextField
            label={t("COMMON.SIZE")}
            {...register("sizeX")}
            onBlur={(e) => {
              update((t) => (t.sizeX = parseInt(e.currentTarget.value)));
            }}
          />
          <TextField
            label={t("COMMON.SIZE_Y")}
            {...register("sizeY")}
            onBlur={(e) => {
              update((t) => (t.sizeY = parseInt(e.currentTarget.value)));
            }}
          />
        </Box>
        <BoardGameTemplate template={template} update={update} />
        {/* <Box display="flex" gap="1rem">
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            padding="6px 14px"
            gap="0.25rem"
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.2)",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
              }}
              variant="body1"
            >
              {t("GAME.COLOR")}
            </Typography>
            <ColorPickerField
              onChange={(color) => {
                update((t) => (t.color = color));
              }}
            />
          </Box>

          <Box
            display="flex"
            flex="1"
            alignItems="center"
            padding="6px 14px"
            gap="0.25rem"
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.2)",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
              }}
              variant="body1"
            >
              {t("GAME.TEXT_COLOR")}
            </Typography>

            <ColorPicker
              selected={form.watch("textColor")}
              onColorChange={(color) => {
                setValue("textColor", color);
                update((t) => (t.textColor = color));
              }}
            />
          </Box>
        </Box> */}
        {/* <TextField
          label={t("TOKEN.SIDE")}
          {...register("fields")}
          onBlur={(e) => {
            update((t) => (t.fields = e.currentTarget.value.split(",")));
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={form.watch("noParse")}
              value={form.watch("noParse")}
              onChange={() => {
                const noParse = form.watch("noParse");
                setValue("noParse", !noParse);
                update((t) => (t.noParse = !noParse));
              }}
            />
          }
          label={t("TOKEN.NO_PARSE")}
        /> */}
      </div>
    </FormProvider>
  );
};
