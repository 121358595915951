import { IconButton, Tooltip } from "@mui/material";
import DifferenceIcon from "@mui/icons-material/Difference";
import { useTranslation } from "react-i18next";
import { useMaps } from "../../map-hex/components/MapProvider";
import { Popover } from "../../../shared/components/ui/popover/Popover";
import { useTemplates } from "../../chat/hooks/useCards";
import SelectField, {
  LoseOption,
} from "../../../shared/components/logic/SelectField";
import { cuni } from "../../../shared/services/cuni/cuni";

interface CardTemplateApplyProps {
  deckId: string;
}
export const CardTemplateApply = (props: CardTemplateApplyProps) => {
  const { deckId } = props;

  const { all, current } = useMaps();
  const deckPlace = current?.deckPlaces.find((x) => x.key === deckId);
  const { t } = useTranslation();

  const templates = useTemplates();
  const template = templates.find((x) => x.key === deckPlace?.templateKey);
  const selectField = (
    <SelectField<LoseOption>
      label={t("CARD_TYPES.TYPE")}
      value={template}
      onChange={(e: LoseOption) => {
        if (!deckPlace) return;
        deckPlace.templateKey = e.key;
        deckPlace.boxes = [];
        cuni.object.update("maps", all, deckPlace);
      }}
      options={templates}
    />
  );

  return (
    <Popover
      renderButton={(onClick) => (
        <Tooltip title={t("GAME.TEMPLATE_USE")}>
          <IconButton onClick={onClick}>
            <DifferenceIcon />
          </IconButton>
        </Tooltip>
      )}
    >
      {selectField}
    </Popover>
  );
};
