import React from "react";
import "./CardPrintBEM.css";

type Token = {
  id: string;
  imageUrl: string;
};

type Card = {
  id: string;
  title: string;
  backgroundImage: string;
  tokens: Token[];
};

type CardPrintProps = {
  cards: Card[];
};

const CardPrint: React.FC<CardPrintProps> = ({ cards }) => {
  const pages = Array.from(
    { length: Math.ceil(cards.length / 9) },
    (_, pageIndex) => cards.slice(pageIndex * 9, (pageIndex + 1) * 9),
  );
  return (
    <div className="print-container">
      {pages.map((pageCards, pageIndex) => (
        <div key={`page-${pageIndex}`} className="print-layout">
          {pageCards.map((card) => (
            <div
              key={card.id}
              className="print-layout__card"
              // style={{ backgroundImage: `url(${card.backgroundImage})` }}
            >
              <div
                className="print-layout__card-content"
                style={{ backgroundImage: `url(${card.backgroundImage})` }}
              >
                <h3 className="print-layout__card-title">{card.title}</h3>
                <div className="print-layout__card-tokens">
                  {card.tokens.map((token) => (
                    <div
                      key={token.id}
                      className="print-layout__card-token"
                      style={{ backgroundImage: `url(${token.imageUrl})` }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

{
  /* <div className="print-layout">
{cards.map((card) => (
  <div
    key={card.id}
    className="print-layout__card"
    // style={{ backgroundImage: `url(${card.backgroundImage})` }}
  >
    <div
      className="print-layout__card-content"
      style={{ backgroundImage: `url(${card.backgroundImage})` }}
    >
      <h3 className="print-layout__card-title">{card.title}</h3>
      <div className="print-layout__card-tokens">
        {card.tokens.map((token) => (
          <div
            key={token.id}
            className="print-layout__card-token"
            style={{ backgroundImage: `url(${token.imageUrl})` }}
          ></div>
        ))}
      </div>
    </div>
  </div>
))}
</div> */
}

export default CardPrint;
