import { AvatarImage } from "../../features/character/components/avatar/AvatarImage";
import "./ProjectPage.css";
import { ProjectDetails } from "../../features/project/project-details/ProjectDetails";

// serce - dociagnij kartę
// miecz -  wybrana osoba odrzuca kartę
// topór -  wybrana osoba odrzuca losową kartę
// tarcza - po zagraniu karty zostaw ją przed sobą, chroni Cię jakbyś miał ją na ręce
// krzystał - dociagnij karte z talii odrzuconych (wybierz)
// złoto - + 1 punkt zwycięstwa

// Początek
// Kazdy z graczy dobiera po 10 kart.

// W swojej rundzie możesz
// 1. Zagrać jedną postać królika
// 2. Zagrać jedną kartę akcji
// 3. Dobrac karte
// 4*. Jeżeli nie zagrałeś żadnej karty, dobierz 2 karty
// 5*. Jeżeli nie możesz dobrać karty, i żadnej karty nie zagrałeś, odrzuć jedną kartę

// Cel gry
// Ustalcie ile punktów jest potrzebnych, aby wygrać grę: (np. 10)
// Grajcie tyle razy dopóki ktoś tego nie osiągnie.
// Zwycięzca potyczki dostaje tyle punktów ile jest gracz,
// Drugi o jeden mniej
// Trzeci o dwa mmiej itd.

// -- Akcje --> (Czary)

// Czar wskrzeszenie - dobierz do 4 kart
// Czar gniew natury - wszyscy przeciwnicy tracą 1 kartę, a ty dobierasz 1
// Czar uleczenie - dobierz 2 karty
// Czar kula ognia - wybrana osoba odrzuca dwie losowe karty
// Czar ochronna bańka - Tarcza - podwójna tarcza
// Czar klątwa - połóż przed przeciwnikiem, następny królik zagrywa tylko 1 akcję
// Czar błogosławieństwo - Podwaja siłę jednego królika
// Czar rozproszenie - zagraj, aby zniwelować inną akcję (w dowolnym momencie)
// Czar spowolnienie czasu - połóż przed przeciwnikiem, omija go tura
// Czar gorączka złota - złoto +1 punkt zwycięstwa

// Dodatkowo
// Gdy ktoś osłabi postać lub zaklęcia z dwoma tarczami, połóż na górę zakrytą kartę atakującą i zostaw odsłonięte tylko to co dalej jest aktywne w grze.
// Gdy skończą się karty nie przetasowujesz stosu. Teraz tylko kryształy pozwalają dobrać karty ze stosu kart odrzuconych.

// -- Postaci --
// Rycerz - miecz, tarcza
// Mag - serce, tarcza
// Barb - topór, topór
// Assasin - miecz, miecz
// Druid - serce, kryształ
// Necro - kryszał, krzystał,
// Mnich - serce, serce, serce
// Paladyn - miecz, serce, tarcza
// Straznik - miecz, tarcza, tarcza
// Strzelec - miecz, serce,
// Bard - serce, serce
// Krol - złoto, serce
// Czarnoksięźnik - topór, kryształ, serce,
// Samuraj - miecz, miecz, tarcza
// krasnolud - topór, miecz, serce
// wampir - miecz, tarcza, kryształ

const cards = [
  {
    count: 6,
    operators: ["sword2", "shield"],
  },
  {
    count: 6,
    operators: ["heart", "shield"],
  },
  {
    count: 3,
    operators: ["axe", "axe"],
  },
  {
    count: 6,
    operators: ["sword2", "sword2"],
  },
  {
    count: 6,
    operators: ["magic2", "heart"],
  },
  {
    count: 3,
    operators: ["magic2", "magic2"],
  },
  {
    count: 2,
    operators: ["heart", "heart", "heart"],
  },
  {
    count: 2,
    operators: ["sword2", "shield", "heart"],
  },
  {
    count: 2,
    operators: ["sword2", "shield", "shield"],
  },
  {
    count: 6,
    operators: ["sword2", "heart"],
  },
  {
    count: 6,
    operators: ["heart", "heart"],
  },
  {
    count: 1,
    operators: ["gold2", "heart"],
  },
  {
    count: 2,
    operators: ["axe", "magic2", "heart"],
  },
  {
    count: 2,
    operators: ["sword2", "sword2", "shield"],
  },
  {
    count: 2,
    operators: ["axe", "sword2", "heart"],
  },
  {
    count: 2,
    operators: ["sword2", "shield", "magic2"],
  },
  {
    count: 1,
    operators: ["gold2"],
  },
  {
    count: 6,
    operators: ["sword2", "sword2"],
  },
  {
    count: 6,
    operators: ["shield", "shield"],
  },
  {
    count: 4,
    operators: [],
  },
  {
    count: 4,
    operators: [],
  },
  {
    count: 4,
    operators: [],
  },
  {
    count: 6,
    operators: [],
  },
  {
    count: 6,
    operators: ["heart", "heart"],
  },
  {
    count: 2,
    operators: ["heart", "heart", "heart", "heart"],
  },
  {
    count: 3,
    operators: ["sword", "heart"],
  },
  {
    count: 1,
    operators: ["magic2", "magic2", "magic2"],
  },
  {
    count: 1,
    operators: ["axe", "axe", "axe"],
  },
  {
    count: 1,
    operators: ["sword2", "sword2", "shield", "shield"],
  },
  {
    count: 1,
    operators: ["sword", "sword", "sword2"],
  },
  {
    count: 1,
    operators: ["sword2", "sword2", "sword2", "magic2"],
  },
  {
    count: 1,
    operators: ["sword2", "sword2", "sword2", "sword2"],
  },
  {
    count: 1,
    operators: ["shield", "shield", "shield", "shield"],
  },
  {
    count: 1,
    operators: ["sword", "magic2", "magic2"],
  },
  {
    count: 1,
    operators: ["sword", "sword2", "sword2", "magic2"],
  },
];

const baseUrl =
  "https://cuni.fra1.cdn.digitaloceanspaces.com/cuni/image_part_0";
const cardsUrl = "https://cuni.fra1.cdn.digitaloceanspaces.com/cuni_cards/";

const m_cards = [
  "m_gold",
  "m_fire",
  "m_shield",
  "m_holy",
  "m_curse",
  "m_time",
  "m_cancel",
  "m_heart",
  "m_multi_heart",
  "m_vortex",
  "shaman",
  "berserker",
  "archon",
  "rider",
  "archer",
  "assasin",
  "fly",
  "ghost",
  "d_archon",
];

export const ProjectPage = () => {
  let allUrls = Array.from(Array(16).keys()).map((x) => {
    const next = x + 1;
    const urlPath = next > 9 ? `${next}` : `0${next}`;
    const imgUrl = `${baseUrl}${urlPath}.jpg`;
    return imgUrl;
  });

  allUrls = [...allUrls, ...m_cards.map((x) => `${cardsUrl}${x}.webp`)];

  const all = allUrls.map((imgUrl, idy) => {
    return (
      <div
        key={imgUrl}
        className="avatarModal__item"
        onClick={(e) => {
          //@ts-ignore
          print3(document.getElementsByClassName("avatarModal__item")[idy]);
        }}
      >
        <div className="card-generator-operators">
          {(
            cards[idy]?.operators || [
              "axe",
              "sword2",
              "shield",
              "magic2",
              "heart",
            ]
          ).map((x, idx) => (
            <div
              style={{
                backgroundImage: `url(https://cuni.fra1.cdn.digitaloceanspaces.com/cuni_cards/${x}.webp)`,
              }}
              className="operator-heart"
            ></div>
          ))}
        </div>

        {/* <div className="card-generator-count">{cards[idy]?.count}</div> */}
        <div className="card-generator-frame"></div>

        <AvatarImage imgUrl={imgUrl} online />
      </div>
    );
  });

  const rab = cards.slice(0, 16).reduce((p, c) => p + c.count, 0);
  const magic = cards.slice(16, 26).reduce((p, c) => p + c.count, 0);
  console.log(rab, magic, rab + magic);
  console.log(cards.reduce((p, c) => p + c.count, 0));
  return (
    <div className="page card-generator">
      {/* <ProjectDetails /> */}
      <div className="card-generator-container">{all}</div>
    </div>
  );
};
