import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGame } from "../../../features/character/hooks/useGame";
import { Conditional } from "../logic/Conditional";
import { useGamePermission } from "../../hooks/useGamePermission";
import EditIcon from "@mui/icons-material/Edit";

export const NavigateBack = () => {
  const navigate = useNavigate();
  const { game } = useGame();
  const { hasGamePermission } = useGamePermission();

  return (
    <div
      style={{
        position: "fixed",
      }}
      className="navigate-back"
    >
      <IconButton onClick={() => navigate("/list")}>
        <CancelIcon />
      </IconButton>
      <Conditional condition={hasGamePermission("canEditGame")}>
        <IconButton onClick={() => navigate(`/game/${game.id}/modify`)}>
          <EditIcon />
        </IconButton>
      </Conditional>
    </div>
  );
};
