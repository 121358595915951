import React, { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import "./BoardGameTemplate.css";
import { IconButton, TextField } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { GameTamplateBox, GameTemplate } from "../../model/Game";
import { uuid } from "../../shared/utils/uuid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Crop75Icon from "@mui/icons-material/Crop75";
import LabelIcon from "@mui/icons-material/Label";

interface BoardGameTemplateProps {
  template: GameTemplate;
  update: (updater: (t: GameTemplate) => void) => void;
}

export const BoardGameTemplate: React.FC<BoardGameTemplateProps> = (
  props: BoardGameTemplateProps,
) => {
  const { template, update } = props;
  const [boxes, setBoxes] = useState<GameTamplateBox[]>(template.boxes || []);
  const [boxControls, setBoxControls] = useState<boolean>(true);

  // Add a new box with default settings
  const addBox = (shape: "rectangle" | "circle" | "label") => {
    setBoxes([
      ...boxes,
      {
        id: uuid(),
        x: 50,
        y: 50,
        width: 100,
        height: shape === "label" ? 56 : 100,
        shape,
        rotation: 0, // Default rotation
      },
    ]);
  };
  const copy = (box: GameTamplateBox) => {
    setBoxes([...boxes, box]);
  };

  const remove = (id: string) => {
    setBoxes(boxes.filter((x) => x.id !== id));
  };

  // Update box properties (for drag and resize)
  const updateBox = (id: string, data: Partial<GameTamplateBox>) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) => (box.id === id ? { ...box, ...data } : box)),
    );
  };
  // Rotate the box by a specified angle
  const rotateBox = (id: string, angle: number) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === id ? { ...box, rotation: box.rotation + angle } : box,
      ),
    );
  };

  useEffect(() => {
    update((t) => {
      t.boxes = boxes;
    });
  }, [boxes]);

  return (
    <div className="board-game-template">
      <div className="controls paper-outline ">
        <IconButton onClick={() => addBox("rectangle")}>
          <Crop75Icon />
        </IconButton>
        <IconButton onClick={() => addBox("circle")}>
          <RadioButtonUncheckedIcon />
        </IconButton>
        <IconButton onClick={() => addBox("label")}>
          <LabelIcon />
        </IconButton>
        <IconButton onClick={() => setBoxControls(!boxControls)}>
          {boxControls ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </div>
      <div className="canvas-container">
        <div
          className="canvas paper"
          style={{
            width: template.sizeX,
            height: template.sizeY,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${template.backgroundUrl})`,
          }}
        >
          {boxes.map((box) => (
            <Rnd
              key={box.id}
              size={{ width: box.width, height: box.height }}
              position={{ x: box.x, y: box.y }}
              onDragStop={(e, d) => updateBox(box.id, { x: d.x, y: d.y })}
              onResizeStop={(e, direction, ref, delta, position) => {
                updateBox(box.id, {
                  width: ref.offsetWidth,
                  height: ref.offsetHeight,
                  ...position,
                });
              }}
              bounds="parent"
            >
              <div
                className={`box ${box.shape}`}
                style={{
                  width: "100%",
                  height: "100%",
                  transform: `rotate(${box.rotation}deg)`, // Apply rotation
                  borderRadius: box.shape === "circle" ? "50%" : "8px",
                }}
              >
                {box.shape === "label" ? (
                  <TextField className="label-box" />
                ) : (
                  <></>
                )}
              </div>

              {boxControls && (
                <div className="rotation-controls paper  disable-map deck-place-in-action">
                  <IconButton
                    onClick={() => rotateBox(box.id, -15)}
                    size="small"
                  >
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => rotateBox(box.id, 15)}
                    size="small"
                  >
                    <RotateRightIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      copy({ ...box, x: box.x + 10, y: box.y + 10, id: uuid() })
                    }
                    size="small"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => remove(box.id)}
                    size="small"
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              )}
            </Rnd>
          ))}
        </div>
      </div>
    </div>
  );
};
