import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface SwitchViewProps {
  viewComponents: Record<string, JSX.Element>;
  view: string | null;
  setView: (view: null) => void;
}
export const SwitchView = (props: PropsWithChildren<SwitchViewProps>) => {
  const { children, viewComponents, view, setView } = props;
  const { t } = useTranslation();
  const backButton = (
    <Button
      variant="text"
      startIcon={<ArrowBackIosIcon />}
      onClick={() => setView(null)}
    >
      {t("COMMON.BACK")}
    </Button>
  );

  const result = view ? (
    <div>
      {backButton}
      {viewComponents[view]}
    </div>
  ) : (
    children
  );
  return <div className="paper column">{result}</div>;
};
