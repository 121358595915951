import { Box, Button, TextField } from "@mui/material";
import { config } from "../../../config";
import { apiClient } from "../../../shared/services/apiClient";
import { useState } from "react";

const generateImage = async (prompt: string) => {
  try {
    const response = await apiClient
      .post(`${config.baseUrl}/api/ai/generate-image`, {
        prompt,
      })
      .then((res) => res.data);
    return response;
  } catch (error) {}
};

interface GenerateImageProps {
  update: (url: string) => void;
}

export const GenerateImage = (props: GenerateImageProps) => {
  const { update } = props;
  const [prompt, setPrompt] = useState<string>("");
  return (
    <Box display="flex" flexDirection="column" gap="1rem" flex="1">
      <TextField
        multiline
        minRows={3}
        label="Prompt"
        onChange={(e) => setPrompt(e.target.value)}
        value={prompt}
      />
      <TextField label="Url" />

      <Button
        variant="outlined"
        onClick={async () => {
          const image = await generateImage(prompt);
          update(image.response.imageUri);
        }}
      >
        Start
      </Button>
    </Box>
  );
};
