import { MapHexDrop } from "../../../features/map-hex/MapHexDrop";
import { Game, mapIdToPath } from "../../../model/Game";
import {
  WebHookMessage,
  WebHookSubType,
  WebHookType,
} from "../../../model/WebHookMessage";
import { webHookStore } from "../../../store/WebHookStore";
import { findPath } from "../../utils/jsonUtils";
import { dev } from "../../utils/log";
import { gameStorage } from "../storage";
import { rollDice } from "./utils";

// @ts-ignore
window.dev = dev.return;

// Utility function to introduce a delay (returns a promise)
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Create an action queue
let actionQueue: any[] = [];
let isProcessing = false;

// Function that processes the action queue
export async function processQueue() {
  if (isProcessing) return; // If already processing, skip
  isProcessing = true;

  while (actionQueue.length > 0) {
    const action = actionQueue.shift(); // Get the next action from the queue

    action(); // Execute the action body
    await delay(100); // Wait for the specified delay before the next action
  }

  isProcessing = false; // Mark processing as done
}

// TODO: forbid update for ADMIN only
const update = (charId: number, path: string, value: string | number | any) => {
  console.debug(charId, path, value);
  const gamePartPath = mapIdToPath(charId);
  if (gamePartPath) {
    const game = gameStorage.get<Game>();
    if (!game)
      throw new Error("You should be in game context when send message.");
    if (!game.root[gamePartPath]) {
      updateGame(gamePartPath, {
        [path]: value,
      });
      // @ts-ignore
    } else updateGame(`${gamePartPath}.${path}`, value);
    return;
  }
  const game = gameStorage.get<Game>();
  if (!game)
    throw new Error("You should be in game context when send message.");
  const gameId = game.id;

  webHookStore.push({
    type: WebHookType.GameUpdate,
    subType: "GAME_UPDATE",
    metaData: {
      value,
      charId,
      gameId,
      path,
    },
  });
};

const updateGame = (path: string, value: string | number | any) => {
  // const updateGame = (path: keyof Game["root"], value: string | number | any) => {

  const run = () => {
    console.debug("root", path, value);
    const game = gameStorage.get<Game>();
    if (!game)
      throw new Error("You should be in game context when send message.");
    const gameId = game.id;

    webHookStore.push({
      type: WebHookType.GameUpdate,
      subType: "GLOBAL_GAME_UPDATE",
      metaData: {
        value,
        gameId,
        path,
      },
    });
  };
  run();
  // actionQueue.push(run);
  // processQueue();
};

/* background: rgba(100,100, 0, 0.8); */
const log = (
  message: string,
  color: string = "gray",
  user: string = "SYSTEM",
  subType: WebHookSubType,
  receiverId?: number,
  receiver?: string,
) => {
  const game = gameStorage.get<Game>();
  if (!game)
    throw new Error("You should be in game context when send message.");
  const gameId = game.id;

  webHookStore.push({
    type: WebHookType.UserText,
    user,
    message,
    color,
    subType,
    receiverId,
    receiver,
    metaData: {
      gameId,
    },
  });
};

const rollProps = () => ({
  color: "aquamarine",
  subType: "ACTION_ROLL" as WebHookSubType,
});

interface OnBoard {
  type: "OnBoard";
  items: MapHexDrop[];
}
interface OnHand {
  type: "OnHand";
  cardKeyList?: string[];
  deckInstanceKey?: string;
}

type LogContext = OnBoard | OnHand;

const simpleLog = (message: string, context: LogContext) => {
  pushMessage((game) => {
    return {
      type: WebHookType.UserText,
      ...rollProps(),
      user: `${game.me.name}`,
      message,
    };
  });
};

const likeRoll = (diceName: string, result: string) => {
  pushMessage((game) => {
    return {
      type: WebHookType.UserText,
      ...rollProps(),
      user: `${game.me.name}`,
      message: `${diceName} => ${result}.`,
    };
  });
};

const roll = (formula: string) => {
  pushMessage((game) => {
    const expressionResult = rollDice(formula);
    return {
      type: WebHookType.UserText,
      ...rollProps(),
      user: "ROLL",
      message: `${formula} => ${expressionResult}.`,
    };
  });
};

const userRoll = (formula: string, name?: string) => {
  pushMessage((game) => {
    const expressionResult = rollDice(formula);
    return {
      type: WebHookType.UserText,
      ...rollProps(),
      user: `${name || game.me.name}`,
      message: `${formula} => ${expressionResult}`,
    };
  });
};

const refreshMessage = (messageId: number) => {
  pushMessage(() => {
    return {
      type: WebHookType.UserTextRefresh,
      subType: "CHAT_REFRESH",
      contextObjectId: messageId,
    };
  });
};

type ComposeMessage = (game: Game) => WebHookMessage;
const pushMessage = (compose: ComposeMessage) => {
  try {
    const game = gameStorage.get<Game>();
    if (!game)
      throw new Error("You should be in game context when send message.");

    const hook = compose(game);
    if (hook.metaData) {
      hook.metaData.gameId = game.id;
    } else {
      hook.metaData = { gameId: game.id };
    }

    webHookStore.push(hook);
  } catch {
    return "nope";
  }
};

const getAndUpdateMessage = (messageId: number) =>
  webHookStore.getAndUpdateMessage(messageId);

// const ask = {};
// const addRule = {};
// const addAction = {};
// const reaction = {}; // User executes "action" on some "rule"

const makePath = (resource: string, path: string | null) => {
  return path ? `${resource}.${path}` : resource;
};
/**
 * Push to array
 */
const push = <T>(
  resource: keyof Game["root"],
  mainObject: any,
  secondaryObject: T[],
  newObject: T,
) => {
  const path = findPath(mainObject, secondaryObject);
  const finalPath = makePath(resource, path);
  updateGame(`${finalPath}.${secondaryObject.length + 100}`, newObject);
};

const removeLast = <T>(
  resource: keyof Game["root"],
  mainObject: any,
  secondaryObject: T[],
) => {
  const path = findPath(mainObject, secondaryObject);
  const finalPath = makePath(resource, path);
  cuni.updateGame(`${finalPath}.${secondaryObject.length - 1}`, null);
};

const remove = <T>(
  resource: keyof Game["root"],
  mainObject: any,
  secondaryObject: T,
) => {
  const path = findPath(mainObject, secondaryObject);
  const finalPath = makePath(resource, path);
  cuni.updateGame(`${finalPath}`, null);
};

const updateObject = <T>(
  resource: keyof Game["root"],
  mainObject: any,
  secondaryObject: T,
) => {
  const path = findPath(mainObject, secondaryObject);
  const finalPath = makePath(resource, path);
  cuni.updateGame(`${finalPath}`, secondaryObject);
};

export const cuni = {
  update,
  updateGame,
  debug: log,
  log: simpleLog,
  likeRoll,
  roll,
  userRoll,
  pushMessage,
  refreshMessage,
  getAndUpdateMessage,
  array: {
    push,
    removeLast,
  },
  object: {
    remove,
    update: updateObject,
  },
};
declare var window: any;
window.cuni = cuni;
