import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useGame } from "../../character/hooks/useGame";
import { Map, MapItem } from "../../../model/Map";
import { cuni } from "../../../shared/services/cuni/cuni";
import { GameDice, GameToken } from "../../../model/Game";
import { uuid } from "../../../shared/utils/uuid";

// Navigation
interface MapState {
  current: Map | null;
  onRemove: () => void;
  onPrev: () => void;
  markAsActive: () => void;
  onNext: () => void;
  changeType: () => void;
  addToken: (token: GameToken) => void;
  addDice: (token: GameDice) => void;

  addDeck: () => void;

  addDiscardPile: (instanceKey: string) => void;
  addBackground: (input: string) => void;
  updateHex: (id: string, update: Partial<MapItem>) => void;
  admin?: boolean;
  all: Map[];
}

const MapContext = createContext<MapState>({
  current: null,
  onRemove: () => {},
  onPrev: () => {},
  markAsActive: () => {},
  onNext: () => {},
  addDeck: () => {},
  updateHex: () => {},
  changeType: () => {},
  addBackground: () => {},
  addToken: () => {},
  addDice: () => {},
  addDiscardPile: () => {},
  all: [],
});

interface MapProviderProps {
  admin?: boolean;
}

export const MapProvider = (props: PropsWithChildren<MapProviderProps>) => {
  const { admin } = props;
  const [index, setIndex] = useState(0);

  const { game } = useGame();

  const onRemove = useCallback(() => {
    const maps = game.root?.maps || [];
    const current = maps?.[index];
    const toRemove = maps.find((x) => x.id === current.id);
    cuni.object.remove("maps", maps, toRemove);
  }, [game, index]);

  const value = useMemo(() => {
    const all = game?.root?.maps || [];
    const current = admin ? all?.[index] : all.find((x) => x.active)!;
    if (!current) console.warn("There is no active map");
    return {
      current,
      onRemove,
      onNext: () =>
        setIndex((i) => Math.max(0, Math.min(i + 1, all?.length - 1))),
      onPrev: () => setIndex((i) => Math.max(i - 1, 0)),
      markAsActive: () => {
        all.forEach((x) => {
          x.active = x.id === current.id;
        });
        cuni.updateGame("maps", all);
      },
      addBackground: (input: string) => {
        all.forEach((x) => {
          if (current.id === x.id) current.background = input;
        });
        cuni.updateGame("maps", all);
      },
      changeType: () => {
        all.forEach((x) => {
          if (current.id === x.id) {
            current.isSquare = !current.isSquare;
          }
        });
        cuni.updateGame("maps", all);
      },
      addToken: (token: GameToken) => {
        current.tokens ??= [];
        if (current.tokens.some((x) => x.key === token.key)) {
          current.tokens = current.tokens.filter((x) => x.key !== token.key);
        } else {
          current.tokens.push(token);
        }

        cuni.updateGame("maps", all);
      },
      addDice: (token: GameDice) => {
        current.dices ??= [];
        if (current.dices.some((x) => x.key === token.key)) {
          current.dices = current.dices.filter((x) => x.key !== token.key);
        } else {
          current.dices.push(token);
        }

        cuni.updateGame("maps", all);
      },
      addDeck: () => {
        current.deckPlaces ??= [];
        current.deckPlaces ??= [];
        cuni.array.push("maps", all, current.deckPlaces, {
          key: uuid(),
          type: "deck",
          cards: [],
        });
      },
      addDiscardPile: (subTypeDeckInstanceKey: string) => {
        current.deckPlaces ??= [];
        cuni.array.push("maps", all, current.deckPlaces, {
          key: uuid(),
          type: "deck",
          subType: "discardPile",
          subTypeDeckInstanceKey,
          cards: [],
        });
      },
      updateToken: () => {},
      updateHex: (id: string, update: Partial<MapItem>) => {
        for (let i = 0; i < current.grid.length; i++) {
          const row = current.grid[i];
          for (let j = 0; j < row.length; j++) {
            const hex = row[j];
            if (hex.id === id) {
              current.grid[i][j] = { ...hex, ...update };
              break;
            }
          }
        }
        cuni.updateGame("maps", all);
      },
      admin,
      all,
    };
  }, [game, onRemove, index, admin]);

  return (
    <MapContext.Provider value={value}>{props.children}</MapContext.Provider>
  );
};

export const useMaps = () => useContext(MapContext);
