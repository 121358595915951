import "./ElementViewContainer.css";
import {
  MapGenericElement,
  MapUserAreaElement,
} from "../../../model/MapElement";
import { CharacterTabProvider } from "../../character/components/generic/CharacterTabProvider";
import { GenericPanel } from "../../character/components/generic/GenericPanelView";
import { QuickEditModalProvider } from "../../quick-edit/hooks/useQuickEdit";
import { updatePanel, useGamePanels } from "../../tokens/hooks/useGameDbPanels";
import { getBottomZIndex, useMapDrag } from "../../dices/use-map-drag";
import { useHover } from "../../../shared/hooks/useHover";
import { useMapDragHandler } from "../../dices/MapDragProvider";
import { useMaps } from "./MapProvider";
import { cuni } from "../../../shared/services/cuni/cuni";
import { uuid } from "../../../shared/utils/uuid";
import { CardFlyweight, CharacterCardPanel } from "../../../model/Game";
import { PanelMenuProps } from "../../character/components/generic/components/panel-menu/PanelMenu";
import { useUser } from "../../auth/useUser";
import { useGame } from "../../character/hooks/useGame";
import { removeFromHand, usePutOnTable } from "../../board/use-put-on-table";
import { MapHexDrop } from "../MapHexDrop";
import { useCards, useCharacter } from "../../chat/hooks/useCards";
import { Tooltip, IconButton } from "@mui/material";
import i18next, { t } from "i18next";
import { ReactComponent as CardRemoveIcon } from "../../../assets/icons/card_remove.svg";
import { ReactComponent as CardOnTable } from "../../../assets/icons/card_on_table.svg";
import { ReactComponent as CardOnTableHidden } from "../../../assets/icons/card_on_table_hidden.svg";
import { MapCardPlace } from "../../../model/Map";
import { UserAreaElementView } from "./UserAreaElementView";
import { ZoomElementIconButton } from "../../cards/components/ZoomElementIconButton";
import { GameCardView } from "../../cards/GameCardView";

interface ElementViewContainerProps {
  element: MapGenericElement;
}
export const ElementViewContainer = (props: ElementViewContainerProps) => {
  const { element } = props;

  const panels = useGamePanels();

  const user = useUser();
  const handlers = useMapDrag({
    draggableDb: "elements",
    key: element.key,
    position: element.position,
    fixed: false,
    classNameSelector: "element__move",
  });

  const { game } = useGame();
  const { isHovered, ...hoverHandlers } = useHover();
  const { current: currentDragHandler } = useMapDragHandler();
  const { all, current } = useMaps();

  const onMouseUp = (e: any) => {
    if (
      current &&
      currentDragHandler.current &&
      isHovered &&
      element.type === "PANEL"
    ) {
      const panel = panels.find((x) => x.panel.key === element.panelKey);
      if (panel?.panel.__type === "card") {
        const currentCard = current.deckPlaces.find(
          (x) => x.key === currentDragHandler.current,
        );
        if (currentCard?.type !== "card" || !currentCard.card?.key) return;

        const cardKey = currentCard.card.key;
        updatePanel(element.panelKey, (state: CharacterCardPanel) => {
          state.cards.push({
            key: uuid(),
            deckFlyweightKey: currentCard.deckFlyweightKey ?? currentCard.key,
            cardKey,
          });
          return state;
        });
        const toRemove = current.deckPlaces.find(
          (x) => x.key === currentCard.key,
        );
        e.stopPropagation();
        e.preventDefault();
        cuni.object.remove("maps", all, toRemove);
        cuni.log(i18next.t("LOG.ON_HAND"), {
          type: "OnHand",
          cardKeyList: [currentCard.card.key],
        });
      }
    }
  };

  let additionalStyles = {};
  let component = null;
  if (element.type === "PANEL") {
    const elementUserId = element.ownerId;
    const userIds = element.userIds;

    component = (
      <PanelRender
        panelKey={element.panelKey}
        panelOnMap={{
          visible: Boolean(userIds && userIds?.length > 1),
          toggle:
            elementUserId === user?.id
              ? () => {
                  if (!current) return;
                  const mapElement = current.elements.find(
                    (x) => x.key === element.key,
                  );
                  if (mapElement?.type !== "PANEL") return;
                  if (mapElement.userIds && mapElement.userIds?.length > 1)
                    mapElement.userIds = user?.id ? [user?.id] : [];
                  else
                    mapElement.userIds = Object.entries(game?.characters).map(
                      (x) => x[1].__userId,
                    );
                  cuni.object.update("maps", all, mapElement);
                }
              : undefined,
          remove:
            elementUserId === user?.id
              ? () => {
                  if (!current) return;
                  const toRemove = current.elements.find(
                    (x) => x.key === element.key,
                  );
                  cuni.object.remove("maps", all, toRemove);
                }
              : undefined,
        }}
      />
    );
  } else if (element.type === "USER_AREA") {
    component = <UserAreaElementView userArea={element} />;

    additionalStyles = {
      zIndex: getBottomZIndex(current!),
      borderRadius: element.uiType === "ROUND" ? "50%" : 8,
    };
  }

  if (component)
    return (
      <div
        className="element__move disable-map"
        {...handlers}
        {...hoverHandlers}
        style={{
          ...handlers.style,
          ...additionalStyles,
        }}
        onMouseUp={onMouseUp}
      >
        {component}
      </div>
    );
  return <></>;
};

interface PanelRenderProps extends Pick<PanelMenuProps, "panelOnMap"> {
  panelKey: string;
}
// We can reuse PanelRender in TokenView
export const PanelRender = (props: PanelRenderProps) => {
  const { panelKey, panelOnMap } = props;
  const panels = useGamePanels();
  const panelTuple = panels.find((x) => x.panel.key === panelKey);
  const { game } = useGame();

  const { dropOnTable } = usePutOnTable();
  const cards = useCards();
  const { current, all } = useMaps();
  const panelCharacter = useCharacter({ userId: panelTuple?.db?.__userId });

  if (!panelTuple) return <></>;

  const createItem = (inCard: CardFlyweight) => {
    const card = cards.find((x) => x.key === inCard.cardKey);
    const drop: MapHexDrop = {
      instance: inCard,
      card: card!,
      panelKey: panelTuple.panel?.key,
      type: "FROM_HAND",
    };
    return drop;
  };

  const getDiscardPile = (
    cardInstance: CardFlyweight,
  ): MapCardPlace | undefined => {
    let discardPile = current?.deckPlaces.find(
      (x) => x.subTypeDeckInstanceKey === cardInstance.deckFlyweightKey,
    );
    if (!discardPile) {
      discardPile = current?.deckPlaces.find(
        (x) => x.key === cardInstance.deckFlyweightKey,
      );
    }
    return discardPile;
  };

  const moveToDiscardPile = (cardInstance: CardFlyweight) => {
    const discardPile = getDiscardPile(cardInstance);
    if (!discardPile) {
      console.error("Cannot find discarpile");
      return;
    }

    removeFromHand([createItem(cardInstance)]);
    discardPile.cards ??= [];
    cuni.array.push("maps", all, discardPile.cards, cardInstance.cardKey);
    cuni.log(i18next.t("LOG.ON_DISCARD"), {
      type: "OnHand",
      cardKeyList: [cardInstance.cardKey],
      deckInstanceKey: discardPile.key,
    });
  };

  const onDropAll = () => {
    const dropables: MapHexDrop[] = [];
    const toRemove: MapHexDrop[] = [];

    (panelTuple.panel as CharacterCardPanel).cards.forEach((card) => {
      const dropable = createItem(card);
      const discardPile = getDiscardPile(card);
      if (discardPile) {
        discardPile.cards ??= [];
        cuni.array.push("maps", all, discardPile.cards, card.cardKey);
        toRemove.push(dropable);
      } else dropables.push(dropable);
    });

    if (toRemove.length) removeFromHand(toRemove);
    if (dropables.length) dropOnTable(dropables, undefined, { x: -600, y: 0 });

    cuni.log(i18next.t("LOG.ON_DISCARD_ALL"), {
      type: "OnHand",
    });
  };

  const moveOnTable = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cardInstance: CardFlyweight,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    dropOnTable(createItem(cardInstance));
    cuni.log(i18next.t("LOG.ON_BOARD"), {
      type: "OnHand",
      cardKeyList: [cardInstance.cardKey],
    });
  };

  const moveToDiscardPileClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cardInstance: CardFlyweight,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    moveToDiscardPile(cardInstance);
  };

  const actions = (card: CardFlyweight) => (
    <div className="paper block-map disable-map deck-place-in-action">
      <Tooltip title={t("CARD.ON_TABLE")}>
        <IconButton
          size="small"
          onClick={(e) => moveOnTable(e, card)}
          className="block-map disable-map no-events"
        >
          <CardOnTable style={{ width: 24, height: 24 }} />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("CARD.TO_DISCARD_PILE")}>
        <IconButton
          size="small"
          onClick={(e) => moveToDiscardPileClick(e, card)}
          className="block-map disable-map no-events"
        >
          <CardRemoveIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("CARD.PICK_REVERSE")}>
        <IconButton
          size="small"
          onClick={(e) => moveOnTable(e, card)}
          className="block-map disable-map no-events"
        >
          <CardOnTableHidden style={{ width: 24, height: 24 }} />
        </IconButton>
      </Tooltip>

      <ZoomElementIconButton
        children={<GameCardView type="deck" deckInstanceKey="" card={card} />}
      />
    </div>
  );
  const style = { borderColor: panelCharacter?.__color };
  const className: string[] = ["paper-outline", "overflow-visible"];
  if (game.root.currentPlayerId === panelTuple.db?.__userId) {
    className.push("player-round");
    style.borderColor = "chartreuse";
  }

  return (
    <CharacterTabProvider
      skipTab
      key={`${panelTuple.db.dbKey}_${panelTuple.db.panelKey}`}
      character={panelTuple.db}
    >
      <QuickEditModalProvider character={panelTuple.db}>
        <GenericPanel
          style={style}
          className={className.join(" ")}
          panel={panelTuple.panel}
          path={panelTuple.panel.__path!}
          isOnMap
          panelOnMap={{
            actions: panelTuple.panel.__type === "card" ? actions : undefined,
            ...panelOnMap!,
            onDropAll:
              panelTuple.panel.__type === "card" ? onDropAll : undefined,
          }}
        />
      </QuickEditModalProvider>
    </CharacterTabProvider>
  );
};

export interface UserAreaElementViewProps {
  userArea: MapUserAreaElement;
}
