import { Tooltip, IconButton } from "@mui/material";
import { t } from "i18next";
import { MapCardPlace } from "../../../model/Map";
import EditIcon from "@mui/icons-material/Edit";
import { useCards, useDecks } from "../../chat/hooks/useCards";
import { SwitchView } from "../../../shared/components/ui/switch-view/SwitchView";
import { CardDetailEditView } from "../CardDetailEditView";
import { useState } from "react";
import CopyToClipboardButton from "../../../shared/components/logic/CopyToClipboardButton";
import { useDeckProperties } from "./useDeckProperties";
import { DeckDetailEditView } from "../../decks/DeckDetailEditView";

export interface EditDeckPlaceProps {
  deck: MapCardPlace;
}
export const EditDeckPlace = (props: EditDeckPlaceProps) => {
  const { deck } = props;
  const cards = useCards();
  const decks = useDecks();
  const editCard = cards.find((x) => x.key === deck.card?.key);
  const editDeck = decks.find((x) => x.key === deck.deckKey);
  const [view, setView] = useState<string | null>(null);
  const { avatarUrl } = useDeckProperties(deck);

  const onEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (editCard) setView("card");
    else if (editDeck) setView("deck");
  };

  return (
    <div>
      <SwitchView
        setView={setView}
        view={view}
        viewComponents={{
          card: editCard ? <CardDetailEditView cards={[editCard]} /> : <></>,
          deck: editDeck ? <DeckDetailEditView deck={editDeck} /> : <></>,
        }}
      >
        <CopyToClipboardButton textToCopy={avatarUrl || ""} />
        <Tooltip title={t("CARD.DELETE")}>
          <IconButton onClick={onEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </SwitchView>
    </div>
  );
};
