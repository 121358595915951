// File: DynamicRowsComponent.tsx
import React, { useState } from "react";
import { Box, TextField, Button, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GameCardToken } from "../../../model/Game";

interface Row {
  url: string;
  x?: string;
  y?: string;
  width?: string;
  height?: string;
}

interface DynamicRowsComponentProps {
  tokens: GameCardToken[];
  onChange: (tokens: GameCardToken[]) => void;
}

const DynamicRowsComponent = (props: DynamicRowsComponentProps) => {
  const { tokens, onChange } = props;
  const { t } = useTranslation(); // Translation hook
  const [rows, setRows] = useState<Row[]>(tokens);

  const handleInputChange = (
    index: number,
    field: keyof Row,
    value: string,
  ) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
    onChange(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { url: "" }]);
  };

  const handleRemoveRow = (index: number) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        {t("dynamicRows.title")} {/* Translation Key: dynamicRows.title */}
      </Typography>
      <Grid container spacing={2}>
        {rows.map((row, index) => (
          <Grid key={index} item xs={12}>
            <Paper sx={{ p: 2 }} elevation={3}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label={t("dynamicRows.fields.url")} // Translation Key: dynamicRows.fields.url
                    value={row.url}
                    onChange={(e) =>
                      handleInputChange(index, "url", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    fullWidth
                    label={t("dynamicRows.fields.x")} // Translation Key: dynamicRows.fields.x
                    value={row.x}
                    type="number"
                    onChange={(e) =>
                      handleInputChange(index, "x", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    fullWidth
                    label={t("dynamicRows.fields.y")} // Translation Key: dynamicRows.fields.y
                    value={row.y}
                    type="number"
                    onChange={(e) =>
                      handleInputChange(index, "y", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    fullWidth
                    label={t("dynamicRows.fields.width")} // Translation Key: dynamicRows.fields.width
                    value={row.width}
                    type="number"
                    onChange={(e) =>
                      handleInputChange(index, "width", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    fullWidth
                    label={t("dynamicRows.fields.height")} // Translation Key: dynamicRows.fields.height
                    value={row.height}
                    type="number"
                    onChange={(e) =>
                      handleInputChange(index, "height", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveRow(index)}
                  >
                    {t("dynamicRows.actions.remove")}{" "}
                    {/* Translation Key: dynamicRows.actions.remove */}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleAddRow} fullWidth>
            {t("dynamicRows.actions.add")}{" "}
            {/* Translation Key: dynamicRows.actions.add */}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DynamicRowsComponent;
