import { RefObject, useCallback, useMemo } from "react";
import {
  GameCard,
  CharacterCardPanel,
  CharacterTemplatePanel,
} from "../../model/Game";
import { cuni } from "../../shared/services/cuni/cuni";
import { cardUtils } from "../../shared/utils/cardUtils";
import { uuid } from "../../shared/utils/uuid";
import { getTopIndex } from "../dices/use-map-drag";
import {
  MapHexDrop,
  MapHexHandDeckDrop,
  MapHexHandDrop,
} from "../map-hex/MapHexDrop";
import { mapUtils } from "../map-hex/mapUtils";
import { updatePanel } from "../tokens/hooks/useGameDbPanels";
import { useMaps } from "../map-hex/components/MapProvider";
import { useGame } from "../character/hooks/useGame";
import { useCards } from "../chat/hooks/useCards";
import { GamePosition } from "../../model/GamePosition";
import { DropTargetMonitor } from "react-dnd";
import { PRESSED_KEYS } from "../../shared/components/router/components/Layout";
import i18next from "i18next";
import { useMousePosition } from "../../shared/hooks/use-mouse-position";
import { rootStore } from "../../store/RootStore";

interface UsePutOnTable {
  mapRef?: RefObject<HTMLDivElement>;
}

export const removeFromHand = (many: MapHexDrop[]) => {
  if (many.every((drop) => drop.type === "FROM_HAND")) {
    const { panelKey } = many[0] as MapHexHandDrop;
    const keys = many.map((x) => (x as MapHexHandDrop).instance.key);
    updatePanel(
      panelKey,
      (state: CharacterCardPanel | CharacterTemplatePanel) => {
        if (state.__type === "card")
          state.cards = state.cards.filter((x) => !keys.includes(x.key));
        else if (state.__type === "template") {
          state.boxes = state.boxes.filter((x) => !keys.includes(x.key));
        }
        return state;
      },
    );
  }
  if (many.every((drop) => drop.type === "FROM_HAND_DECK")) {
    const keys = many.map((x) => (x as MapHexHandDeckDrop).instance.key);

    const deck = rootStore
      .game!.root.maps.find((x) => x.active)
      ?.deckPlaces.flatMap((x) => x.boxes)
      .filter(Boolean)
      .find((x) => keys.includes(x?.key!));

    cuni.object.remove("maps", rootStore.game!.root.maps, deck);
  }
};

export const usePutDetails = () => {
  const { game } = useGame();
  const cards = useCards();
  const getPutDetails = useCallback(
    (drop: MapHexDrop) => {
      let card: GameCard | undefined = undefined;
      let deckFlyweightKey: string = "";
      if (drop.type === "FROM_TABLE") {
        const { deck } = drop;
        const cardId = cardUtils.pickFromDeck(game, deck.key);
        card = cards.find((x) => x.key === cardId);
        if (deck.type === "deck") {
          deckFlyweightKey = deck.key;
        } else if (deck.type === "card" && deck.deckFlyweightKey) {
          deckFlyweightKey = deck.deckFlyweightKey;
        }
      } else if (drop.type === "FROM_HAND" || drop.type === "FROM_HAND_DECK") {
        card = drop.card;
        deckFlyweightKey = drop.instance.deckFlyweightKey;
      } else {
        throw new Error("Weird type");
      }

      return { card, deckFlyweightKey };
    },
    [cards, game],
  );
  return getPutDetails;
};

const lastDiff = { x: -2, y: -5 };

const manipulate = (pos: GamePosition) => {
  pos.x += lastDiff.x;
  pos.y += lastDiff.y;
  lastDiff.x = Math.floor(Math.random() * 30) - 15;
  lastDiff.y = Math.floor(Math.random() * 30) - 15;
  return pos;
};
export const usePutOnTable = (props: UsePutOnTable = {}) => {
  const { game } = useGame();
  const { mapRef } = props;
  const { current, all } = useMaps();

  const getPutDetails = usePutDetails();

  const mouse = useMousePosition();
  const dropOnTable = useCallback(
    (
      input: MapHexDrop | MapHexDrop[],
      monitor?: DropTargetMonitor<MapHexDrop, unknown>,
      position?: GamePosition,
    ) => {
      if (!current) return;

      const array = Array.isArray(input) ? input : [input];
      for (let i = 0; i < array.length; i++) {
        const drop = array[i];

        let newCoordinates: GamePosition =
          position ??
          (game.root.pointerPosition
            ? manipulate(game.root.pointerPosition)
            : null) ??
          manipulate({ x: 0, y: 0 });
        if (mapRef?.current) {
          const { top, left } = mapRef.current!.getBoundingClientRect();
          const offset = monitor!.getClientOffset()!;

          const { x, y } = offset; // ?? mouse.current; // -- to hard for now
          const transformData = mapUtils.getTransformDataFromMapContainer();

          newCoordinates = mapUtils.calculateNewCoordinates(
            x - left,
            y - top,
            transformData,
          );
        }

        const { card, deckFlyweightKey } = getPutDetails(drop);

        current.deckPlaces ??= [];
        cuni.array.push("maps", all, current.deckPlaces, {
          key: uuid(),
          type: "card",
          cards: [],
          card,
          deckFlyweightKey,
          flipped: drop.flipped || PRESSED_KEYS["AltLeft"],
          position: { ...newCoordinates, zIndex: getTopIndex(current) },
        });
      }

      removeFromHand(array);
      cuni.log(i18next.t("LOG.ON_BOARD"), {
        type: "OnBoard",
        items: array,
      });
    },
    [all, current, getPutDetails, mapRef, game],
  );

  return useMemo(() => ({ dropOnTable }), [dropOnTable]);
};
