import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalProps } from "../../shared/components/modal/ModalProps";
import { useCards } from "../chat/hooks/useCards";
import { Table, TableCellInputProps } from "../../shared/components/ui/Table";
import { GameCard, GameDeck } from "../../model/Game";
import SelectField, {
  SelectOption,
} from "../../shared/components/logic/SelectField";
import { useGame } from "../character/hooks/useGame";
import { cuni } from "../../shared/services/cuni/cuni";

export type DeckSetModalProps = ModalProps & {
  deckKey: string;
};

export const DeckSetModal = (props: DeckSetModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const [deck, setDeck] = useState<GameDeck>();
  const { game } = useGame();

  const currentDeck = game.root.settings?.decks.find(
    (x) => x.key === props.deckKey,
  );

  const currentSubset = currentDeck?.subset || [];
  const cards = useCards();

  const { rows, headers } = useMemo(() => {
    const items = deck ? cards.filter((x) => x.deckKey === deck.key) : cards;
    const headers = [
      {
        key: "key",
        title: t("CARD.CARD"),
        cell: (
          value: GameCard,
          idx: number,
          { variant }: TableCellInputProps,
        ) =>
          value.name ? (
            value.name
          ) : value.avatarUrl ? (
            <img
              alt=""
              style={
                variant === "card" ? { maxHeight: 100 } : { maxHeight: 20 }
              }
              src={value.avatarUrl}
            />
          ) : (
            value.key
          ),
      },
      {
        key: "amount",
        title: t("TOKEN.AMOUNT"),
        disabled: true,
      },
    ];
    const rows = items.map((x) => ({
      ...x,
      avatarUrl: x.avatarUrl ? x.avatarUrl : "",
      amount: x.amount ? x.amount : 0,
    }));
    return { rows, headers };
  }, [cards, deck, t]);

  const [selectedRows, setRowSelected] = useState<GameCard[]>(
    cards.filter((x) => currentSubset.includes(x.key)),
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t("CARD.SUBSET_NOTE")}
        </Typography>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("CARD.SUBSET_NOTE_DESC")}
        </Typography>

        <SelectField<SelectOption>
          label={t("CARD.DECK")}
          value={deck}
          onChange={(e: GameDeck) => {
            setDeck(e);
          }}
          options={game.root.settings?.decks || []}
          onClear
        />

        <Table
          titleSufix="cards"
          directList
          headers={headers}
          className="paper-outline modal-max-height"
          rows={rows}
          variant="card"
          actions={["select"]}
          selectedRows={selectedRows}
          onRowSelected={setRowSelected}
        />

        <Button
          variant="contained"
          onClick={() => {
            if (!currentDeck) return;
            // generate subset from set
            const subset: string[] = [];
            selectedRows.forEach((card) => {
              const amount = card.amount ?? 0;
              for (let i = 0; i < amount; i++) {
                subset.push(card.key);
              }
            });
            currentDeck.subset = subset;

            cuni.object.update("settings", game.root.settings, currentDeck);
            handleClose();
          }}
        >
          {t("COMMON.SAVE")}
        </Button>
        <Button onClick={handleClose}> {t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};
