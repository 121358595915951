import { useMemo, useState } from "react";
import CardPrint from "../../features/decks/CardPrint";
import { uuid } from "../../shared/utils/uuid";
import useGameAndDeck from "./useGameAndDeck";
import { config } from "../../config";
import { apiClient } from "../../shared/services/apiClient";
import { Game } from "../../model/Game";

export const CardPrintPage = () => {
  const { gameId, deckId } = useGameAndDeck();
  const [game, setGame] = useState<Game>();

  useMemo(() => {
    const fetch = async () => {
      const result = await apiClient.get<Game>(
        `${config.baseUrl}/game/${gameId}`,
      );
      setGame(result.data);
    };
    fetch();
  }, [gameId]);

  if (!game) return <></>;
  // const cards = game.root.settings?.cards.filter((x) => x.deckKey === deckId);
  const cards = game.root.settings?.cards
    .filter((x) => x.deckKey === deckId)
    .flatMap((x) => Array(x.amount).fill(x));
  if (!cards) return <></>;

  return (
    <CardPrint
      cards={cards.map((card) => ({
        id: uuid(),
        backgroundImage: card.avatarUrl!,
        title: "",
        tokens:
          card.tokens?.map((x: any) => ({
            id: uuid(),
            imageUrl: x.url!,
          })) || [],
      }))}
    />
  );
};
