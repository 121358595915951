import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./AvatarModal.css";
import { AvatarImage } from "../AvatarImage";
import { Button } from "@mui/material";
import { useGame } from "../../../hooks/useGame";
import { useCallback } from "react";
import { cuni } from "../../../../../shared/services/cuni/cuni";
import { ModalProps } from "../../../../../shared/components/modal/ModalProps";
import { useTranslation } from "react-i18next";
import { ColorPicker } from "../../../../../shared/components/ui/color-picker/ColorPicker";

const baseUrl = "https://cuni.fra1.cdn.digitaloceanspaces.com/cuni/image_part_0";

type AvatarModalProps = ModalProps;

export const AvatarModal = (props: AvatarModalProps) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();

  const { game } = useGame();

  const selectAvatar = useCallback(
    (path: string) => {
      if (game.me.key) cuni.update(game.me.key, "__avatarUrl", path);
      handleClose();
    },
    [game, handleClose],
  );

  const allUrls = game.root.overrideAvatars
    ? (game.root.settings?.avatars || []).map((x) => x.avatarUrl)
    : Array.from(Array(16).keys()).map((x) => {
        const next = x + 1;
        const urlPath = next > 9 ? `${next}` : `0${next}`;
        const imgUrl = `${baseUrl}${urlPath}.jpg`;
        return imgUrl;
      });

  const all = allUrls.map((imgUrl) => {
    return (
      <div
        key={imgUrl}
        className="avatarModal__item"
        onClick={() => selectAvatar(imgUrl)}
      >
        <AvatarImage imgUrl={imgUrl} online />
      </div>
    );
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal">
        <Typography variant="h6" component="h2">
          {t("AVATAR.AVATAR")}
        </Typography>
        <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
          {t("AVATAR.DESC")}
        </Typography>
        <Box className="avatarModal__container">{all}</Box>

        <Box display="flex" gap="1rem">
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            padding="6px 14px"
            gap="0.25rem"
            sx={{
              border: "1px solid rgba(255, 255, 255, 0.2)",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
              }}
              variant="body1"
            >
              {t("GAME.COLOR")}
            </Typography>

            <ColorPicker
              selected={game.me?.__color}
              onColorChange={(color) => {
                if (!game.me) return;
                game.me.__color = color;
                cuni.update(game.me.key, "__color", color);
              }}
            />
          </Box>
        </Box>

        <Button onClick={handleClose}>{t("COMMON.CLOSE")}</Button>
      </Box>
    </Modal>
  );
};
