import { useState } from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import { Conditional } from "../../../shared/components/logic/Conditional";
import { Button } from "@mui/material";
import { useAws } from "../../../shared/hooks/use-aws";
import { uuid } from "../../../shared/utils/uuid";

interface ImageCropperProps {
  aspect: number;
  imageSrc: string;
  onImageUpdate: (url: string) => void;
}

const ImageCropper = (props: ImageCropperProps) => {
  const { aspect, imageSrc, onImageUpdate } = props;
  const [start, setStart] = useState<boolean>(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const { upload } = useAws();
  // Convert cropped area to a blob and download it
  const getCroppedImage = async (): Promise<void> => {
    if (!imageSrc || !croppedAreaPixels) return;

    const image = new Image();
    image.src = imageSrc;
    image.crossOrigin = "anonymous";

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) return;

      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
      );

      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], uuid(), { type: "image/png" });
          upload([file]).then((data) => {
            const { location } = data![0];
            onImageUpdate(location);
          });
        }
      });
    };
    setStart(false);
  };

  const handleCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <div>
      <Conditional
        condition={start}
        failure={() => (
          <Button variant="contained" onClick={() => setStart(true)}>
            Crop
          </Button>
        )}
        success={() => (
          <>
            <Button variant="contained" onClick={getCroppedImage}>
              Save
            </Button>
            <div
              style={{ position: "relative", width: "100%", height: "400px" }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default ImageCropper;
