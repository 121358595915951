import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useToast } from "../../providers/toast/ToastProvider";

interface CopyToClipboardProps {
  textToCopy: string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardProps> = ({
  textToCopy,
}) => {
  const toast = useToast();
  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.addToast("Copied to clipboard!"); // Optional feedback
      })
      .catch(() => {
        toast.addToast("Failed to copy text.", "ERROR"); // Error handling
      });
  };

  return (
    <Tooltip title="Copy to Clipboard">
      <IconButton color="primary" onClick={handleCopy}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
