import { TextField } from "@mui/material";
import { GameProcess, SETTINGS_ADMIN_KEY } from "../../model/Game";
import { useTranslation } from "react-i18next";
import { useGame } from "../character/hooks/useGame";
import { cuni } from "../../shared/services/cuni/cuni";
import { useForm } from "react-hook-form";

interface ProcessDetailEditViewProps {
  process: GameProcess;
}

interface ProcessForm {
  name: string;
  steps: string;
}

export const ProcessDetailEditView = (props: ProcessDetailEditViewProps) => {
  const { process } = props;

  const { t } = useTranslation();

  const { game } = useGame();

  const { register, setValue } = useForm<ProcessForm>({
    defaultValues: process,
  });

  const updateProcess = (updater: (t: GameProcess) => void) => {
    const processes = game.root.settings?.processes ?? [];
    const dbProcess = processes.find((x) => x.key === process.key);
    if (!dbProcess) return;
    updater(dbProcess);
    cuni.update(SETTINGS_ADMIN_KEY, "processes", processes);
  };
  return (
    <div className="column">
      <TextField
        sx={{
          marginTop: "1rem",
        }}
        label={t("TOKEN.NAME")}
        {...register("name")}
        onBlur={(e) => {
          updateProcess((t) => (t.name = e.currentTarget.value));
        }}
      />
      <TextField
        label={t("COMMON.DESC")}
        multiline
        minRows={10}
        {...register("steps")}
        onBlur={(e) => {
          updateProcess((t) => (t.steps = e.currentTarget.value));
        }}
      />
      {/* <Box display="grid" gap="1rem" gridTemplateColumns="1fr 200px">
        <TextField
          label={t("Process.AVATAR")}
          {...register("avatarUrl")}
          onBlur={(e) => {
            updateProcess((t) => (t.avatarUrl = e.currentTarget.value));
          }}
        />
        <UploadButton
          label={`${t("COMMON.UPLOAD")} ${t("AVATAR.AVATAR")}`}
          onUpload={(data: UploadResult[]) => {
            const { location: avatarUrl } = data![0];
            updateProcess((t) => (t.avatarUrl = avatarUrl));
            setValue("avatarUrl", avatarUrl);
          }}
        />
      </Box> */}
    </div>
  );
};
