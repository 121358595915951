import "./Cards.css";
import { Box, Tab, Tabs, TextField, Typography } from "@mui/material";
import {
  GameCard,
  GameCardToken,
  GameDeck,
  SETTINGS_ADMIN_KEY,
} from "../../model/Game";
import { useTranslation } from "react-i18next";
import { useGame } from "../character/hooks/useGame";
import { cuni } from "../../shared/services/cuni/cuni";
import { Controller, useForm } from "react-hook-form";
import SelectField, {
  SelectOption,
} from "../../shared/components/logic/SelectField";
import { useCards } from "../chat/hooks/useCards";
import { GameCardView } from "./GameCardView";
import { uuid } from "../../shared/utils/uuid";
import { ColorPicker } from "../../shared/components/ui/color-picker/ColorPicker";
import UploadButton, {
  UploadResult,
} from "../../shared/components/logic/UploadButton";
import { useState } from "react";
import { Switch, Case } from "../../shared/components/logic/Switch";
import SettingsIcon from "@mui/icons-material/Settings";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { GenerateImage } from "./design/GenerateImage";
import ImageCropper from "./design/ImageCropper";
import TokenIcon from "@mui/icons-material/Token";
import DynamicRowsComponent from "./design/DynamicRowsComponent";

interface CardDetailEditViewProps {
  cards: GameCard[];
}

interface CardForm {
  name: string;
  avatarUrl: string;
  deckKey: string;
  amount: number;
  uiType: GameCard["uiType"];
  color?: string;
  textColor?: string;
  size?: number;
  sizeY?: number;
  tokens?: GameCardToken[];
}

export const CardDetailEditView = (props: CardDetailEditViewProps) => {
  const { cards: gameCards } = props;

  const { t } = useTranslation();

  const { game } = useGame();

  const form = useForm<CardForm>({
    defaultValues: gameCards.length === 1 ? gameCards[0] : { tokens: [] },
  });
  const { register, control, setValue, watch } = form;
  const cards = useCards();

  const update = (updater: (t: GameCard) => void) => {
    const dbItems = cards.filter((x) =>
      gameCards.map((y) => y.key).includes(x.key),
    );
    if (!dbItems) return;
    dbItems.forEach(updater);
    cuni.update(SETTINGS_ADMIN_KEY, "cards", cards);
  };
  const [view, setView] = useState("general");

  return (
    <div className="column">
      <div className="paper-outline">
        <Tabs
          value={view}
          onChange={(_, nweValue) => setView(nweValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab key="general" value="general" label={<SettingsIcon />} />
          <Tab key="colors" value="colors" label={<ColorLensIcon />} />
          <Tab key="design" value="design" label={<DesignServicesIcon />} />
          <Tab key="tokens" value="tokens" label={<TokenIcon />} />
        </Tabs>
      </div>

      <div className="designing-view">
        <div className="designing-view-left">
          <GameCardView
            type="panel"
            panelKey=""
            card={{
              cardKey: gameCards[0].key,
              // this is fake
              deckFlyweightKey: uuid(),
              key: uuid(),
            }}
          />
        </div>
        <div className="designing-view-right">
          <Switch current={view}>
            <Case
              value="general"
              render={
                <>
                  <TextField
                    label={t("COMMON.NAME")}
                    {...register("name")}
                    onBlur={(e) => {
                      update((t) => (t.name = e.currentTarget.value));
                    }}
                  />
                  <Box display="flex" gap="1rem" width="100%">
                    <TextField
                      style={{
                        flex: 1,
                      }}
                      label={t("COMMON.SIZE")}
                      {...register("size")}
                      onBlur={(e) => {
                        update(
                          (t) => (t.size = parseInt(e.currentTarget.value)),
                        );
                      }}
                    />
                    <TextField
                      style={{
                        flex: 1,
                      }}
                      label={t("COMMON.SIZE_Y")}
                      {...register("sizeY")}
                      onBlur={(e) => {
                        update(
                          (t) => (t.sizeY = parseInt(e.currentTarget.value)),
                        );
                      }}
                    />
                  </Box>
                  <Box
                    display="grid"
                    gap="1rem"
                    gridTemplateColumns="1fr 200px"
                  >
                    <TextField
                      label={t("TOKEN.AVATAR")}
                      {...register("avatarUrl")}
                      onBlur={(e) => {
                        update((t) => (t.avatarUrl = e.currentTarget.value));
                      }}
                    />
                    <UploadButton
                      label={`${t("COMMON.UPLOAD")}`}
                      onUpload={(data: UploadResult[]) => {
                        const { location: avatarUrl } = data![0];
                        update((t) => (t.avatarUrl = avatarUrl));
                        setValue("avatarUrl", avatarUrl);
                      }}
                    />
                  </Box>
                  <TextField
                    label={t("TOKEN.AMOUNT")}
                    {...register("amount")}
                    onBlur={(e) => {
                      update(
                        (t) => (t.amount = parseInt(e.currentTarget.value)),
                      );
                    }}
                  />

                  <Controller
                    name="uiType"
                    control={control}
                    render={({ field }) => {
                      return (
                        <SelectField<SelectOption>
                          label={t("CARD_TYPES.TYPE")}
                          value={{
                            key: field.value,
                            name: t(`CARD_TYPES.${field.value}`),
                          }}
                          onChange={(e: SelectOption) => {
                            const uiType =
                              typeof e === "object" && "key" in e
                                ? (e.key as GameCard["uiType"])
                                : "CARD";
                            setValue("uiType", uiType);
                            update((t) => (t.uiType = uiType));
                          }}
                          options={["FREE", "SQUARE", "ROUND", "CARD"].map(
                            (x) => ({
                              key: x,
                              name: t(`CARD_TYPES.${x}`),
                            }),
                          )}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="deckKey"
                    control={control}
                    render={({ field }) => {
                      const deck = game.root.settings?.decks?.find(
                        (x) => x.key === field.value,
                      );
                      return (
                        <SelectField<SelectOption>
                          label={t("CARD.DECK")}
                          value={deck}
                          onChange={(e: GameDeck) => {
                            setValue("deckKey", e.key);
                            update((t) => (t.deckKey = e.key));
                          }}
                          options={game.root.settings?.decks || []}
                        />
                      );
                    }}
                  />
                </>
              }
            />
            <Case
              value="colors"
              render={
                <>
                  <Box display="flex" gap="1rem">
                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      padding="6px 14px"
                      gap="0.25rem"
                      sx={{
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                        variant="body1"
                      >
                        {t("GAME.COLOR")}
                      </Typography>
                      <ColorPicker
                        selected={form.watch("color")}
                        onColorChange={(color) => {
                          setValue("color", color);
                          update((t) => (t.color = color));
                        }}
                      />
                    </Box>

                    <Box
                      display="flex"
                      flex="1"
                      alignItems="center"
                      padding="6px 14px"
                      gap="0.25rem"
                      sx={{
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                        variant="body1"
                      >
                        {t("GAME.TEXT_COLOR")}
                      </Typography>

                      <ColorPicker
                        selected={form.watch("textColor")}
                        onColorChange={(color) => {
                          setValue("textColor", color);
                          update((t) => (t.textColor = color));
                        }}
                      />
                    </Box>
                  </Box>
                </>
              }
            />

            <Case
              value="design"
              render={
                <>
                  <GenerateImage
                    update={(url: string) => {
                      setValue("avatarUrl", url);
                      update((t) => (t.avatarUrl = url));
                    }}
                  />
                  <ImageCropper
                    onImageUpdate={(url: string) => {
                      setValue("avatarUrl", url);
                      update((t) => (t.avatarUrl = url));
                    }}
                    imageSrc={watch("avatarUrl")}
                    aspect={(() => {
                      const y = watch("sizeY") || 240;
                      const x = watch("size") || 160;
                      return x / y;
                    })()}
                  />
                </>
              }
            />
            <Case
              value="tokens"
              render={
                <>
                  <DynamicRowsComponent
                    tokens={watch("tokens") || []}
                    onChange={(tokens) => {
                      setValue("tokens", tokens);
                      update((t) => (t.tokens = tokens));
                    }}
                  />
                </>
              }
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};
