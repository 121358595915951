import React, { useMemo } from "react";
import { Card, CardContent, Typography, ButtonBase, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./MarketPlace.css";
import { CreateGameForm, useGamePost } from "./modal/CreateGameModal";
import { apiClient } from "../../shared/services/apiClient";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MarketplaceUserItem } from "../../model/MarketplaceUserItem";
import { config } from "../../config";

type CardProps = {
  title: string;
  description: string;
  onClick: () => void;
};

const MarketplaceCard: React.FC<CardProps> = ({
  title,
  description,
  onClick,
}) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        width: 200,
        height: 100,
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: 2,
          backgroundColor: "#2E3B55", // A dark blue-grey color to contrast with black backgrounds
          color: "#FFFFFF",
          transition: "transform 0.3s ease, background-color 0.3s ease",
          "&:hover": {
            transform: "scale(1.05)",
            backgroundColor: "#3A4B6B", // Slightly lighter shade on hover
          },
          boxShadow: 3,
        }}
      >
        <CardContent sx={{ textAlign: "center", padding: 1 }}>
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};
const info = [
  {
    title: "CUNI",
    url: "https://cuni.fra1.cdn.digitaloceanspaces.com/games/game_cuni.json",
  },
  {
    title: "CARDS",
    url: "https://cuni.fra1.cdn.digitaloceanspaces.com/games/game-cards.json",
  },
];

export const MarketPlace = () => {
  const { t, i18n } = useTranslation();
  const { mutate } = useGamePost();
  const queryClient = useQueryClient();

  const moreInfo = useGetMarketplaceItems();
  console.log(moreInfo.data);

  const finalMarketplaceData = useMemo(() => {
    if (!moreInfo.data?.length) return info;
    return [...moreInfo.data.map((x) => x.marketplaceItem), ...info];
  }, [moreInfo.data]);

  const onSubmit = async (item: (typeof info)[0]) => {
    const result = await fetch(item.url);
    const json = await result.json();
    const form: CreateGameForm = {
      name: json.name,
      payload: JSON.stringify(json),
      type: "board",
      url: json.url,
    };

    mutate(form, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["game-all"],
          exact: true,
        });
      },
    });
  };
  return (
    <div className="paper marketplace" style={{ flexBasis: "100%" }}>
      <Typography variant="h5">{t("COMMON.MARKETPLACE")}</Typography>
      <Box display="flex" gap={2}>
        {finalMarketplaceData.map((x) => (
          <MarketplaceCard
            title={x.title}
            description={
              i18n.exists(`MARKETPLACE.${x.title}`)
                ? t(`MARKETPLACE.${x.title}`)
                : ""
            }
            onClick={() => onSubmit(x)}
          />
        ))}
      </Box>
    </div>
  );
};

export const useGetMarketplaceItems = () => {
  return useQuery({
    queryKey: ["marketplaceUserItem"],
    queryFn: () =>
      apiClient
        .get<MarketplaceUserItem[]>(
          `${config.baseUrl}/api/marketplaceuseritem?expand=MarketplaceItem`,
        )
        .then((res) => res.data),
  });
};
