import { useTranslation } from "react-i18next";
import { ReactComponent as CardLook } from "../../../assets/icons/card_look.svg";
import {
  Tooltip,
  IconButton,
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ModalProps } from "../../../shared/components/modal/ModalProps";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GameGrantPermission } from "../../../model/Game";
import { uuid } from "../../../shared/utils/uuid";
import { useUser } from "../../auth/useUser";
import { useMaps } from "../../map-hex/components/MapProvider";
import { useCards, useDecks } from "../../chat/hooks/useCards";
import { CardView } from "../CardView";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGranPermission } from "./useGranPermission";
import { Conditional } from "../../../shared/components/logic/Conditional";

export const CardLookThrough = (props: { deckId: string }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  return (
    <>
      <CardLookThroughModal {...props} open={open} handleClose={close} />
      <Tooltip title={t("CARD.LOOK_THROUGH")}>
        <IconButton onClick={() => setOpen(true)}>
          <CardLook style={{ width: 24, height: 24 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

type CardLookThroughModalProps = ModalProps & {
  deckId: string;
};

interface FormProps {
  begin: number;
  length: number;
}

export interface UseGrantPermissionProps {
  close: () => void;
}
export const CardLookThroughModal = (props: CardLookThroughModalProps) => {
  const { open, handleClose: close, deckId } = props;

  const { t } = useTranslation();
  const { register, handleSubmit, reset, getValues } = useForm<FormProps>({
    defaultValues: {
      length: 1,
    },
  });

  useEffect(() => {
    reset({
      length: 1,
    });
  }, [reset]);

  const { accepted, askForPermission, handleClose, loading } =
    useGranPermission({ close });

  const user = useUser();
  const cards = useCards();
  const decks = useDecks();
  const onSubmit = (form: FormProps) => {
    if (!user) return;

    let message = "";
    let deckPlace = current?.deckPlaces.find((x) => x.key === props.deckId);
    if (!deckPlace) return;
    if (deckPlace.subType === "discardPile") {
      const originalDeckPlace = current?.deckPlaces.find(
        (x) => x.key === deckPlace!.subTypeDeckInstanceKey,
      );
      message = t("CARD.DISCARDED");
      deckPlace = originalDeckPlace;
    }
    const deck = decks.find((x) => x.key === deckPlace?.deckKey);
    message = `${deck?.name}${message ? ` (${message})` : ""}`;

    const gamePermission: GameGrantPermission = {
      key: uuid(),
      creatorId: user.id,
      question: t("CARD.LOOK_THROUGH_QUESTION", {
        user: user.userName,
        length: form.length,
        begin: form.begin || "0",
        deck: message,
      }),
      answers: [],
    };
    askForPermission(gamePermission);
  };

  const { current } = useMaps();
  const currentPlace = current?.deckPlaces.find((x) => x.key === deckId);

  const cardIds =
    currentPlace?.cards.slice(
      -getValues("length"),
      -getValues("begin") || undefined,
    ) || [];

  const component = loading ? (
    <Box display="flex" justifyContent="center" padding="1rem">
      <CircularProgress />
    </Box>
  ) : accepted ? (
    <>
      <Box display="flex" gap="1rem" className="modal-max-height">
        {cardIds.map((x, index) => {
          const data = cards.find((card) => card.key === x);
          return <CardView {...data} key={x} index={index} />;
        })}
        <ArrowBackIcon />
      </Box>
    </>
  ) : (
    <>
      <Typography id="modal-avatar-description" sx={{ mt: 2 }}>
        {t("CARD.LOOK_THROUGH_DESC")}
      </Typography>

      <TextField
        multiline
        type="number"
        label={t("TOKEN.AMOUNT")}
        {...register("length")}
      />
      <TextField
        multiline
        type="number"
        label={t("COMMON.START")}
        {...register("begin")}
      />
    </>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="modal">
          <Typography variant="h6" component="h2">
            {t("CARD.LOOK_THROUGH")}
          </Typography>

          {component}

          <Conditional condition={!accepted && !loading}>
            <Button type="submit" variant="contained">
              {t("COMMON.SEND")}
            </Button>
          </Conditional>

          <Button onClick={handleClose}>{t("COMMON.CLOSE")}</Button>
        </Box>
      </form>
    </Modal>
  );
};
