import ReorderIcon from "@mui/icons-material/Reorder";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import GridOnIcon from "@mui/icons-material/GridOn";
import { Button } from "@mui/material";
import { CharacterTabPanel } from "../../../../model/Game";
import ImageIcon from "@mui/icons-material/Image";
import { cuni } from "../../../../shared/services/cuni/cuni";
import { useCharacterTabs } from "../../../character/components/generic/CharacterTabProvider";
import { NewTableModal } from "./components/NewTableModal";
import { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { file } from "../../../../shared/utils/file";
import { useTranslation } from "react-i18next";
import { uuid } from "../../../../shared/utils/uuid";
import { ReactComponent as CardIcon } from "../../../../assets/icons/card.svg";
import { isPreview } from "../../../../config";
import DescriptionIcon from "@mui/icons-material/Description";
import { usePlanPermissions } from "../../../../shared/hooks/usePlanPermissions";
import { PaymentTooltip } from "../../../../shared/components/ui/PaymentTooltip";

interface GameTabEditActionsProps {}

export const GameTabEditActions = (props: GameTabEditActionsProps) => {
  const { tab, tabId, character } = useCharacterTabs();
  const [modal, setModal] = useState("");
  const { t } = useTranslation();
  const { hasPermission } = usePlanPermissions();

  const addPanel = (newPanel: CharacterTabPanel) => {
    if (tab.title === "main") {
      const panels = character.__panels ?? [];
      panels.push(newPanel);
      cuni.update(character.key, "__panels", panels);
    } else {
      const panels = tab.__panels ?? [];
      panels.push(newPanel);
      cuni.update(character.key, `__tabs.${tabId - 1}.__panels`, panels);
    }
  };

  const importPanel = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = await file.import(event);
    result.key = uuid();
    addPanel(result);
  };

  return (
    <div className="paper paper-sm flex" style={{ gap: "0.5rem" }}>
      <Button
        variant="outlined"
        onClick={() => setModal("ADD_TABLE")}
        startIcon={<GridOnIcon />}
      >
        {t("COMMON.TABLE")}
      </Button>
      <Button
        variant="outlined"
        onClick={() =>
          addPanel({
            key: uuid(),
            __type: "generic",
            title: "NEW_LIST_PANEL",
            __panels: null,
          })
        }
        startIcon={<ReorderIcon />}
      >
        {t("COMMON.LIST")}
      </Button>
      <Button
        variant="outlined"
        onClick={() =>
          addPanel({
            key: uuid(),
            __type: "text",
            title: "NEW_TEXT_PANEL",
            richText: ``,
            __panels: null,
          })
        }
        startIcon={<CropSquareIcon />}
      >
        {t("COMMON.TEXT")}
      </Button>
      <Button
        variant="outlined"
        onClick={() =>
          addPanel({
            key: uuid(),
            __type: "card",
            title: "NEW_CARD_PANEL",
            cards: [],
            __panels: null,
          })
        }
        startIcon={<CardIcon style={{ width: 24, height: 24 }} />}
      >
        {t("CARD.DECK")}
      </Button>
      <Button
        disabled={isPreview}
        variant="outlined"
        onClick={() =>
          addPanel({
            key: uuid(),
            __type: "template",
            title: "NEW_TEMPLATE_PANEL",
            boxes: [],
            __panels: null,
          })
        }
        startIcon={<DescriptionIcon />}
      >
        {t("CARD.TEMPLATE")}
      </Button>
      <Button
        disabled={isPreview}
        variant="outlined"
        onClick={() =>
          addPanel({
            key: uuid(),
            __type: "template",
            title: "NEW_TEMPLATE_PANEL",
            boxes: [],
            __panels: null,
          })
        }
        startIcon={<DescriptionIcon />}
      >
        {t("CARD.TEMPLATE")}
      </Button>
      <Button
        variant="outlined"
        onClick={() =>
          addPanel({
            key: uuid(),
            __type: "image",
            title: "url://image",
            richText: ``,
            __panels: null,
          })
        }
        startIcon={<ImageIcon />}
      >
        {t("COMMON.IMAGE")}
      </Button>
      <input
        type="file"
        id="panelFileInput"
        style={{ display: "none" }}
        accept="application/json"
        onChange={importPanel}
      />
      <PaymentTooltip
        title={t("INFO.UPGRADE")}
        visible={!hasPermission("canExportImport")}
      >
        <Button
          disabled={!hasPermission("canExportImport")}
          variant="outlined"
          onClick={() => {
            document.getElementById("panelFileInput")?.click();
          }}
          startIcon={<UploadIcon />}
        >
          {t("COMMON.IMPORT")}
        </Button>
      </PaymentTooltip>
      <NewTableModal
        addPanel={addPanel}
        open={modal === "ADD_TABLE"}
        handleClose={() => setModal("")}
      />
    </div>
  );
};
