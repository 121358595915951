import { Button } from "@mui/material";
import { useState } from "react";
import { DeckSetModal, DeckSetModalProps } from "./DeckSetModal";
import { useCards } from "../chat/hooks/useCards";
import { useGame } from "../character/hooks/useGame";
import { CardView } from "../cards/CardView";
import { useTranslation } from "react-i18next";

interface DeckSetViewProps extends Pick<DeckSetModalProps, "deckKey"> {}
export const DeckSetView = (props: DeckSetViewProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { game } = useGame();
  const cards = useCards();
  const currentDeck = game.root.settings?.decks.find(
    (x) => x.key === props.deckKey,
  );

  const currentSubset = currentDeck?.subset || [];
  const components = cards
    .filter((x) => currentSubset.includes(x.key))
    .map((x) => <CardView {...x} />);

  return (
    <>
      <DeckSetModal {...props} open={open} handleClose={() => setOpen(false)} />
      <Button variant="contained" onClick={() => setOpen(true)}>
        {t("CARD.CREATE_SUBSET")}
      </Button>
      <div style={{ display: "flex", flexWrap: "wrap" }}>{components}</div>
    </>
  );
};
