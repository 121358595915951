import { useDrag } from "react-dnd";
import { useCards, useDecks } from "../chat/hooks/useCards";
import { CardFlyweight } from "../../model/Game";
import { MapHexDrop } from "../map-hex/MapHexDrop";
import { CardView } from "./CardView";
import { usePutOnTable } from "../board/use-put-on-table";
import { PanelProxy } from "./PanelProxy";
import { DeckInstanceProxy } from "./DeckInstanceProxy";

interface BaseGameCardViewProps {
  card: CardFlyweight;
  actions?: JSX.Element;
  style?: React.CSSProperties;
}

interface PanelGameCardViewProps extends PanelProxy, BaseGameCardViewProps {}
interface DeckGameCardViewProps
  extends DeckInstanceProxy,
    BaseGameCardViewProps {}

type GameCardViewProps = PanelGameCardViewProps | DeckGameCardViewProps;

export const GameCardView = (props: GameCardViewProps) => {
  const cards = useCards();
  const decks = useDecks();

  const card = cards.find((x) => x.key === props.card.cardKey);
  const deck = decks.find((x) => x.key === card?.deckKey);
  const updateKey =
    props.type === "deck" ? props.deckInstanceKey : props.panelKey;

  const createIem = (): MapHexDrop => {
    if (props.type === "deck") {
      return {
        instance: props.card,
        type: "FROM_HAND_DECK",
        card: card!,
        deckKey: props.deckInstanceKey!,
      };
    }
    return {
      instance: props.card,
      card: card!,
      panelKey: props.panelKey!,
      type: "FROM_HAND",
    };
  };

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "TOKEN-CARD",
      item: createIem(),
      collect: (monitor) => {
        return {
          opacity: monitor.isDragging() ? 0 : 1,
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [props.card.key, updateKey],
  );

  const { dropOnTable } = usePutOnTable();

  const onDoubleClick = () => {
    dropOnTable(createIem());
  };

  if (!card) return <></>;

  // if (props.type === "deck")
  //   return (
  //     <div ref={dragRef}>
  //       <DeckPlaceContainer
  //         deck={
  //           current?.deckPlaces.find((x) => x.key === props.deckInstanceKey)!
  //         }
  //       />
  //     </div>
  //   );
  return (
    <CardView
      dragRef={dragRef}
      {...card}
      color={card.color || deck?.cardColor}
      textColor={card.textColor || deck?.cardTextColor}
      isDragging={isDragging}
      uiType={card.uiType}
      onDoubleClick={onDoubleClick}
      actions={props.actions}
      style={props.style}
    />
  );
};
