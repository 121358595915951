import { useEffect, useRef, useState } from "react";
import "./MapHex.css";
import { Conditional } from "../../shared/components/logic/Conditional";
import { IconButton, Tooltip } from "@mui/material";
import PanToolIcon from "@mui/icons-material/PanTool";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { HexRow } from "./components/Hex";
import { MapActions } from "./components/MapActions";
import { MapProvider, useMaps } from "./components/MapProvider";
import { HexActionProvider } from "./components/HexActionProvider";
import { SquareRow } from "./components/Square";
import MapInteractionCSS from "../../shared/components/ui/map-interaction/src/MapInteractionCSS";
import { TokenLink } from "./components/TokenLink";
import { Popover } from "../../shared/components/ui/popover/Popover";
import { TokenView } from "../tokens/TokenDetailEditView";
import { DeckPlaceContainer } from "../cards/components/DeckPlaceContainer";
import { useDrop } from "react-dnd";
import { useMergedRefs } from "../../shared/hooks/useMergedRefs";
import { DiceViewContainer } from "../dices/DiceViewContainer";
import GestureIcon from "@mui/icons-material/Gesture";
import {
  MapDrawingLayer,
  MapDrawingLayerType,
} from "./components/MapDrawingLayer";
import { ColorPicker } from "../../shared/components/ui/color-picker/ColorPicker";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { MapImagesView } from "./components/MapImagesView";
import { MapDragProvider } from "../dices/MapDragProvider";
import { ElementViewContainer } from "./components/ElementViewContainer";
import { useUser } from "../auth/useUser";
import { usePutOnTable } from "../board/use-put-on-table";
import { useTranslation } from "react-i18next";
import { ClearAllCards } from "./components/ClearAllCards";
import DoNotTouchIcon from "@mui/icons-material/DoNotTouch";
import { cuni } from "../../shared/services/cuni/cuni";
import { ViewPort } from "../../model/Game";
import { useGame } from "../character/hooks/useGame";
import { mapUtils } from "./mapUtils";
import { AddUserArea } from "./components/AddUserArea";
import { MapPanelElement, MapUserAreaElement } from "../../model/MapElement";

const keyMap: Record<string, [number, number]> = {
  ArrowRight: [-20, 0],
  ArrowLeft: [20, 0],
  ArrowUp: [0, 20],
  ArrowDown: [0, -20],
};

export const MapHexDisplay = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState<{ width: number; height: number }>();
  const { game } = useGame();
  const [transform, setTransform] = useState<ViewPort>(
    game.me?.__viewPort ?? {
      scale: 1,
      translation: { x: 0, y: 0 },
    },
  );

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (Object.keys(keyMap).includes(e.code)) {
        setTransform((tran) => ({
          ...tran,
          translation: {
            x: tran.translation.x + keyMap[e.code][0],
            y: tran.translation.y + keyMap[e.code][1],
          },
        }));
      }
    };
    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
  });

  const newTransforValue = useRef(transform);
  const lastTransformSaved = useRef(transform);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        JSON.stringify(lastTransformSaved.current) ===
          JSON.stringify(newTransforValue.current) ||
        !game.me
      )
        return;
      lastTransformSaved.current = newTransforValue.current;
      cuni.update(game.me.key, "__viewPort", lastTransformSaved.current);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [game.me]);

  useEffect(() => {
    newTransforValue.current = transform;
  }, [transform]);

  useEffect(() => {
    setTimeout(() => {
      if (!mapRef.current) return;
      setLoading(false);
      setSize({
        height: 10000,
        width: 10000,
      });
    }, 10);
  }, []);

  const { current } = useMaps();
  const user = useUser();

  const { dropOnTable } = usePutOnTable({ mapRef });

  const [{ isOver }, drop] = useDrop(
    {
      accept: "TOKEN-CARD",
      drop: dropOnTable,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    },
    [dropOnTable],
  );
  const mergedRef = useMergedRefs(drop, mapRef);

  const [pointer, setPointer] = useState(false);

  const drawLayer = useRef<MapDrawingLayerType>(null);
  const [disabled, setDisabled] = useState(false);
  const [color, setColor] = useState<string>();

  useEffect(() => {
    const onMouseDown = (e: any) => {
      setPointer(false);

      let offset = { x: 0, y: 0 };
      if (e.changedTouches && e.changedTouches.length > 0) {
        const touch = e.changedTouches[0];

        const clientX = touch.clientX;
        const clientY = touch.clientY;

        offset = {
          x: clientX,
          y: clientY,
        };
      } else {
        offset = {
          x: e.clientX,
          y: e.clientY,
        };
      }

      const { top, left } = mapRef.current!.getBoundingClientRect();
      const transformData = mapUtils.getTransformDataFromMapContainer();
      const { x, y } = mapUtils.calculateNewCoordinates(
        offset.x - left,
        offset.y - top,
        transformData,
      );

      if (pointer) {
        cuni.updateGame("pointerPosition", { x, y, zIndex: 5000 });
      }
    };
    document.addEventListener("mousedown", onMouseDown, {
      passive: false,
    });
    return () => {
      document.removeEventListener("mousedown", onMouseDown);
    };
  }, [pointer]);

  return (
    <div
      ref={mergedRef}
      className="map"
      style={{
        border: isOver ? "2px solid gray" : undefined,
      }}
    >
      <MapDrawingLayer ref={drawLayer} color={color} />

      <div className="map__toolbar">
        <Tooltip title={t("MAP.POINTER")}>
          <IconButton
            color={pointer ? "warning" : undefined}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPointer(!pointer);
            }}
          >
            <TouchAppIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("MAP.BLOCK")}>
          <IconButton onClick={() => setDisabled(true)}>
            <DoNotTouchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("MAP.UNBLOCK")}>
          <IconButton>
            <PanToolIcon
              onClick={() => {
                setDisabled(false);
                setColor(undefined);
              }}
            />
          </IconButton>
        </Tooltip>
        <ColorPicker
          //@ts-ignore
          Icon={GestureIcon}
          selected={color}
          onColorChange={setColor}
          title={t("MAP.DRAW")}
        />
        <Tooltip title={t("MAP.CLEAR_LAYER")}>
          <IconButton>
            <LayersClearIcon onClick={() => drawLayer.current?.clear()} />
          </IconButton>
        </Tooltip>
        <AddUserArea />
        <ClearAllCards />
      </div>
      <MapInteractionCSS
        containerRef={drop}
        showControls
        disableZoom={disabled}
        disablePan={disabled}
        value={transform}
        onChange={(value: any) => setTransform(value)}
      >
        <div className="map__container">
          <Conditional condition={!loading}>
            <Conditional
              condition={current}
              success={(mapConfig) => (
                <HexActionProvider>
                  <div
                    className="map__container-inner"
                    style={{
                      ...size,
                      backgroundImage: `url(${mapConfig.background})`,
                    }}
                  >
                    <MapImagesView />
                    {current?.tokens?.map((token) => (
                      <Popover
                        renderButton={(onClick) => (
                          <TokenLink token={token} onClick={onClick} />
                        )}
                      >
                        <TokenView token={token} />
                      </Popover>
                    ))}
                    {current?.dices?.map((dice) => (
                      <DiceViewContainer dice={dice} />
                    ))}
                    {current?.deckPlaces?.map((deck) => (
                      <DeckPlaceContainer deck={deck} />
                    ))}
                    {game.root.pointerPosition && (
                      <div
                        style={{
                          position: "absolute",
                          ...game.root.pointerPosition,
                          top: game.root.pointerPosition.y,
                          left: game.root.pointerPosition.x,
                          // background: "chartreuse",
                          width: 12,
                          height: 12,
                          border: "1px solid chartreuse",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            padding: "2px",
                            background: "chartreuse",
                            border: "1px solid transparent",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    )}
                    {current?.elements
                      ?.filter((x) => x.type === "PANEL")
                      .map((x) => x as MapPanelElement)
                      .filter((x) => user?.id && x.userIds?.includes(user?.id))
                      .map((element) => (
                        <ElementViewContainer element={element} />
                      ))}
                    {current?.elements
                      ?.filter((x) => x.type === "USER_AREA")
                      .map((x) => x as MapUserAreaElement)
                      .map((element) => (
                        <ElementViewContainer element={element} />
                      ))}
                    {mapConfig.grid.map((row) =>
                      mapConfig.isSquare ? (
                        <SquareRow
                          row={row}
                          hasBackground={Boolean(mapConfig.background)}
                        />
                      ) : (
                        <HexRow
                          row={row}
                          hasBackground={Boolean(mapConfig.background)}
                        />
                      ),
                    )}
                  </div>
                </HexActionProvider>
              )}
            ></Conditional>
          </Conditional>
        </div>
      </MapInteractionCSS>
    </div>
  );
};

export const MapHex = () => {
  return (
    <MapProvider admin>
      <MapDragProvider>
        <div className="mapHex">
          <MapActions />
          <MapHexDisplay />
        </div>
      </MapDragProvider>
    </MapProvider>
  );
};
